//comments are based on font-size 100% = 16px
//default
@font-face {
  font-family: 'Karmina Sans';
  src: url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.eot');
  src: url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.woff2') format('woff2'),
    url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.woff') format('woff'),
    url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.ttf') format('truetype'),
    url('../../assets/fonts/KarminaRegular/e03c0aa74096b32428ddd8cce00ef3b7.svg#Karmina Sans W01 Regular')
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Karmina Sans';
  src: url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.eot');
  src: url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.woff2') format('woff2'),
    url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.woff') format('woff'),
    url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.ttf') format('truetype'),
    url('../../assets/fonts/KarminaBold/dc8da48a4babe6a937b15ab7e6745b00.svg#Karmina Sans W01 Bold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$main-font: 'Open Sans', sans-serif;
$font-size: 16px;

//colors
$white: #ffffff;
$black: #16222f;

$primary: #0199f2;
$primary01: #3bacf4;
$primary02: #68c0f6;
$primary03: #99d4fa;
$primary04: #def2ff;

$gray01: #172638;
$gray02: #3c4854;
$gray03: #68717b;
$gray04: #989ea5;
$gray05: #cbcdd1;
$gray07: #e5e7eb;
$gray09: #f6f6f9;

$success01: #19b369;
$success02: #58c18f;
$success03: #9fefc9;

$alert01: #f7553f;
$alert02: #f27766;
$alert03: #ffe2de;

$warning01: #f9c234;
$warning02: #feda7f;
$warning03: #ffebba;

$complementary01: #ffbc42;
$complementary02: #244c7c;
$complementary03: #76cb93;
$complementary04: #d89ea8;
$complementary05: #6fb5e0;

$navy: #14202c;
$yellow: #fffe3e;
$red: #ff3000;
$redHover: #e22e05;
$redActive: #c42b0b;

// Size
$minHeightHeader: 80px;
