.Header-import {
  background: #ffffff;
  padding: 40px 28px;
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 400px) {
    padding: 40px 16px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5000px;
    background: white;
  }
  &::after {
    left: 100%;
  }
  &::before {
    right: 100%;
  }
  a {
    margin-bottom: 16px;
  }
  .Header-import__steps {
    padding: 5px 0;
    &__inner {
      display: flex;
      align-items: center;
    }
    &__item {
      display: flex;
      align-items: center;
      &:focus {
        outline: none;
      }
      p {
        margin: 0;
      }
      .number {
        margin-right: 12px;
        border: 2px solid transparent;
        p {
          @include size(24px);
          @include flex_parent_centered();
          background: #ffffff;
          border: 1.5px solid #8a9097;
          border-radius: 8px;
          margin: 2px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &__item.isActive {
      opacity: 1;
      .number {
        border-color: $primary;
        border-radius: 10px;
        p {
          border: none;
          background: $primary;
          color: #ffffff;
        }
      }
    }
    &__item.complete {
      opacity: 1;
      .number {
        p {
          border: none;
          color: #ffffff;
          background: $primary;
        }
      }
    }
    img {
      margin: 0 16px;
    }
    span {
      margin: 0 5.5px;
      color: #8a9097;
      display: none;
    }
    @media (max-width: 1000px) {
      .Header-import__steps__inner {
        display: block;
      }
      img {
        display: none;
      }
      span {
        display: block;
        transform: rotate(90deg);
        width: 23px;
      }
    }
    @media (max-width: 760px) {
      .name-step {
        font-size: 12px;
        line-height: 16px;
      }
      .number {
        margin-right: 6px;
        p {
          @include size(20px);
        }
      }
      span {
        margin: 0 3.5px;
      }
    }

    @media (max-width: 380px) {
      &__item:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.Import-student {
  .Step {
    padding: 40px 16px 7%;
  }
  .step1 {
    &__inner > p {
      margin: 0 12px 24px;
    }
    .type-of-import {
      display: flex;
      justify-content: space-between;
      > div {
        background: #ffffff;
        box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
        border-radius: 8px;
        margin: 0 12px 16px;
        width: calc(33.33% - 24px);
        padding: 24px;
        text-align: center;
        cursor: pointer;
        border: 1.5px solid transparent;
        &:hover,
        &.active {
          border-color: #0199f2;
        }
        &:focus {
          outline: none;
        }
        img {
          height: auto;
          width: 75%;
        }
        .title {
          margin: 10px 0 8px;
        }
      }
      @media (max-width: 750px) {
        display: block;
        > div {
          width: calc(100% - 24px);
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 130px;
          }
          > div {
            text-align: left;
          }
        }
      }
      @media (max-width: 360px) {
        > div {
          padding: 16px;
          img {
            width: 100px;
          }
        }
      }
    }
    .button__group {
      margin: 40px 12px 0;
      text-align: center;
      button {
        width: 200px;
      }
      .button--primary:disabled {
        background: #cbcdd1;
        opacity: 1;
      }
    }
  }

  .step2 {
    &__inner {
      margin: 0 12px;
      &__item {
        margin-bottom: 40px;
        width: 100%;
        max-width: 567px;
        &:last-child {
          max-width: 608px;
        }
        p {
          margin-bottom: 8px;
        }
        svg {
          margin-right: 10px;
        }
      }
    }
    .button-group {
      max-width: 400px;
      margin: 32px 0 0 auto;
      width: 100%;
      button {
        width: 50%;
        max-width: inherit;
      }
      .button--primary:disabled {
        background: #cbcdd1;
        opacity: 1;
      }
    }
    .Documents {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }
  .step3 {
    &__inner {
      margin: 0 12px;
      &__item {
        margin-bottom: 40px;
        width: 100%;
        max-width: 567px;
        &:last-child {
          max-width: 608px;
        }
        p {
          margin-bottom: 8px;
        }
      }
      &__status {
        background: #ffffff;
        box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
        border-radius: 8px;
        padding: 22px 24px;
        &__info {
          @include flex_parent_between();
          flex-wrap: wrap;
          p:last-child {
            cursor: pointer;
            min-width: 215px;
          }
          p:first-child {
            min-width: 130px;
          }
        }
        &__progress {
          width: 100%;
          height: 4px;
          background: $alert02;
          border-radius: 3px;
          margin-bottom: 22px;
          span {
            height: 4px;
            background: $primary;
            display: block;
          }
        }
        &__note {
          display: flex;
          > div {
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 32px;
            }
            span {
              @include size(24px);
              border-radius: 8px;
              margin-right: 16px;
            }
            p {
              margin: 0;
            }
          }
          @media (max-width: 380px) {
            display: block;
            > div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .button-group {
      max-width: 430px;
      margin: 32px 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      button {
        width: calc(50% - 15px);
        max-width: inherit;
        min-width: 172px;
        &:first-child {
          margin: 0 15px 15px 0;
        }
      }
    }
  }
}

.Import-instruction {
  &__header {
    background: #ffffff;
    padding: 40px 28px 20px;
    max-width: 1030px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 400px) {
      padding: 40px 16px 16px;
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    &::after {
      left: 100%;
    }
    &::before {
      right: 100%;
    }
    a {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }
  }
  .select-type {
    padding-top: 45px;
    &__inner > p {
      margin: 0 12px 24px;
    }
    .type-of-import {
      display: flex;
      justify-content: space-between;
      > div {
        background: #ffffff;
        box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
        border-radius: 8px;
        margin: 0 12px 16px;
        width: calc(33.33% - 24px);
        padding: 15px 24px 35px;
        text-align: center;
        cursor: pointer;
        img {
          height: auto;
          width: 75%;
        }
        .title {
          margin: 10px 0 8px;
        }
      }
      @media (max-width: 750px) {
        display: block;
        > div {
          width: calc(100% - 24px);
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 130px;
          }
          > div {
            text-align: left;
          }
        }
      }
      @media (max-width: 360px) {
        > div {
          padding: 16px;
          img {
            width: 100px;
          }
        }
      }
    }
  }
}

.General-information {
  .Step-by-step {
    padding-top: 35px;
    .title {
      margin: 0 12px 10px;
    }
    &__inner {
      > div {
        @include flex_parent_between();
        > div {
          margin: 0 12px;
        }
        @media (max-width: 835px) {
          display: block;
          > div {
            margin: 0 12px 10px;
            width: calc(100% - 24px) !important;
          }
        }
      }
    }
    &__left {
      width: calc(45% - 24px);
      .step__item {
        color: $primary;
        display: flex;
        opacity: 0.4;
        padding-bottom: 14px;
        position: relative;
        &:not(:last-child) {
          &::after {
            content: "";
            border-left: 1px dashed #68c0f6;
            margin-left: 10px;
            display: block;
            position: absolute;
            bottom: 0;
            top: 20px;
          }
        }
        span {
          @include size(20px);
          background: $primary;
          border-radius: 100%;
          color: #ffffff;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          font-size: 14px;
          line-height: 19px;
          margin-right: 16px;
          font-weight: bold;
        }
        p {
          margin: 0;
        }
        &:focus {
          outline: none;
        }
        &.--active {
          opacity: 1;
          p {
            font-weight: 600;
          }
        }
      }
      .border-dashed {
        display: none;
      }
    }
    &__right {
      width: calc(55% - 24px);
      position: relative;
      .Loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      img {
        width: 100%;
        height: auto;
        z-index: 2;
        position: relative;
      }
      p {
        margin: 0 20px;
      }
    }
  }
  .fields-and-requirement {
    padding-bottom: 50px;
    .Table {
      padding: 16px 24px;
      margin: 0;
      box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
      border-radius: 8px;
      tr {
        background: #ffffff;
        &:first-child {
          td {
            padding-top: 20px;
          }
        }
        th {
          color: $black;
          border-bottom: 1px solid #cbcdd1;
          padding: 6px 12px 6px 0;
        }
        td {
          padding: 3px 12px 3px 0;
        }
        th,
        td {
          &:first-child {
            width: 30%;
          }
          &:last-child {
            text-align: left;
          }
        }
      }
      @media (max-width: 500px) {
        padding: 16px;
      }
    }
  }
}
