.Pagination {
  margin: 0 12px;
  padding: 0 12px;
  @include flex_parent_between();
  @media (max-width: 715px) and (min-width: 600px) {
    flex-direction: column;
    .count-page {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
    .count-page {
      margin-bottom: 10px;
    }
  }
  .count-page {
    font-size: 12px;
    line-height: 16px;
  }
  .pagination {
    margin: 0;
    li {
      margin: 0;
      padding: 0;
      display: inline-flex;
      align-items: center;
      &:not(.previous):not(.next) {
        &.active {
          a {
            background: #0199f2;
            color: #ffffff;
          }
        }
        a {
          padding: 8px 0;
          font-size: 12px;
          line-height: 16px;
          height: 32px;
          min-width: 31px;
          border-radius: 4px;
          text-align: center;
          &:hover {
            background: #0199f2;
            color: #ffffff;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .break-me {
      display: none;
    }
    .previous,
    .next {
      display: flex;
      align-items: center;
      width: auto;
      padding: 8px 12px;
      font-weight: 600;
      color: $primary;
      a {
        outline: 0 !important;
      }
      p {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      &:hover {
        color: $primary;
        background: transparent;
      }
      &.disabled {
        color: $gray02;
        & > * {
          cursor: not-allowed;
        }
        path {
          fill: $gray02 !important;
        }
      }
    }
    .previous {
      svg {
        margin-right: 10px;
      }
    }
    .next {
      svg {
        margin-left: 10px;
      }
    }
    p {
      margin: 0;
    }
    @media (max-width: 370px) {
      li {
        width: 28px;
      }
      .previous,
      .next {
        padding: 8px;
      }
      .previous {
        svg {
          margin-right: 4px;
        }
      }
      .next {
        svg {
          margin-left: 4px;
        }
      }
    }
  }
}
