// colors
$utilities-colors: (
  ('primary', $primary),
  ('primary01', $primary01),
  ('white', $white),
  ('black', $black),
  ('gray', $gray01),
  ('gray03', $gray03),
  ('gray04', $gray04),
  ('alert01', $alert01),
  ('alert02', $alert02),
  ('success', $success01)
) !default;

@each $name, $color in $utilities-colors {
  .#{$name} {
    color: $color;
  }

  .background-#{$name} {
    background-color: $color;
  }
}

// radius
.radius {
  border-radius: 16px;

  &-s {
    border-radius: 8px;
  }

  &-l {
    border-radius: 24px;
  }
}

.margin-auto {
  margin: auto;
}

.text-center {
  text-align: center;
}

.--is-active {
  color: $primary !important;
}

.top-section {
  opacity: 0;
  position: absolute;
  bottom: 92px;
  left: 0;
  width: 100%;
  top: calc(-#{$minHeightHeader} + 1px);
  display: none;
}

.underline {
  text-decoration: underline;
}
.italic {
  font-style: italic;
}
