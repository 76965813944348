.Programs {
  .filter {
    @include flex_parent_between();
    margin: 0 12px 24px;
    .form-group {
      width: calc(100% - 120px);
      max-width: 308px;
      margin: 0 15px 0 0;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;
      .form-group {
        width: 100%;
        max-width: inherit;
        margin: 0 0 16px;
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  .Table {
    table {
      tr {
        th,
        td {
          &:first-child {
            width: 30%;
            min-width: 130px;
          }
          &:nth-child(2) {
            width: 30%;
            span {
              margin: 0;
              text-align: right;
              min-width: 60px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.Add-programs {
  form {
    padding: 5px 12px;
  }
  &__text {
    .text--xlarge {
      margin-bottom: 8px;
    }
    .text {
      margin-bottom: 30px;
    }
  }
  &__inner {
    max-width: 310px;
    .form-group {
      margin-bottom: 16px;
    }
  }
  .checkbox-group {
    margin-bottom: 16px;
    p {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .add-code {
    margin-bottom: 10px;
    p {
      font-size: 14px;
      line-height: 19px;
      &:nth-child(2) {
        cursor: pointer;
        margin-bottom: 6px;
      }
    }
  }
  .Code__item {
    margin-bottom: 16px;
    .checkbox {
      display: flex;
      button {
        border: none;
        background: transparent;
        z-index: 15;
        svg {
          path {
            fill: $black;
          }
          &:hover {
            path {
              fill: $alert01;
            }
          }
        }
      }
    }
  }
  .button-group {
    width: 100%;
    max-width: 310px;
    button:last-child {
      width: 65%;
      max-width: 200px;
    }
  }
  .add-licensure {
    margin-bottom: 20px;
    .list__add-licensure {
      display: flex;
      flex-wrap: wrap;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;
    }
    &__item:not(:last-child) {
      margin-right: 16px;
    }
    &__item {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px 30px 16px 24px;
      display: flex;
      max-width: 330px;
      margin-bottom: 16px;
      position: relative;
      &__left {
        margin-right: 30px;
      }
      p {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    &__text {
      .text--xlarge {
        margin-bottom: 6px;
      }
      .text {
        margin-bottom: 20px;
      }
    }
    &__inner {
      .form-group {
        margin-bottom: 12px;
      }
    }
    .checkbox-group {
      margin-bottom: 25px;
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .add-code {
      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 12px;
      }
    }
    .add-licensure__item {
      margin-right: 0 !important;
    }
  }
  @media (max-width: 360px) {
    .button-group {
      button {
        width: 50% !important;
        max-width: inherit;
      }
    }
  }
}

.description {
  padding-left: 24px;
  .btn-collape {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      margin: 0 7px 0 0;
      color: $primary;
    }
    svg {
      margin: 0 0 3px 0;
    }
    &:focus {
      outline: none;
    }
  }

  &__text {
    p {
      margin: 16px 0 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
  @media (max-width: 500px) {
    &__text p {
      margin: 7px 0 0;
      font-size: 11px;
    }
  }
}

.Modal__add-licensure {
  .modal-body {
    max-width: 372px;
    .form-group:not(:last-child) {
      margin-bottom: 16px;
    }
    @media (max-width: 500px) {
      > div {
        margin-bottom: 12px !important;
      }
    }
  }
}
