.header {
  @apply w-full;
  @apply border-b border-gray-200;
  @apply text-center;
}

.smallTitle {
  @apply mb-8;
  @apply font-bold text-sm text-gray-500;
}

.title {
  @apply font-bold text-2xl text-center;
}

.changeRequest {
  @apply w-full p-2;
  @apply bg-yellow-100 text-yellow-800;
  @apply border border-dashed border-yellow-300 rounded-lg;
  @apply text-justify;

  h2 {
    @apply pb-2;
    @apply text-sm font-bold;
  }
}
