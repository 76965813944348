.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 32px;
  text-align: center;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  &--small {
    min-width: 0;
    padding: 0 16px;
    height: 40px;
    border-radius: 16px;
    font-size: 0.9rem; // ~ 14px
  }
}

//color modifier

$button-colors: (
  ('primary', $primary, $white, $primary01),
  ('primary-icon', $primary04, $primary, $primary03),
  ('delete', $alert01, $white, $alert02)
) !default;

@each $name, $background-color, $color, $hover-color in $button-colors {
  .button--#{$name} {
    color: $color;
    background-color: $background-color;
    border: none;
    &:hover,
    &:focus {
      background-color: $hover-color;
    }
  }
}

.button--secondary {
  background: $white;
  border: 2px solid $primary;
  color: $black;
  padding: 0 25px;
  &:hover {
    border-color: $primary01;
  }
}

.button--icon {
  @include flex_parent_centered();
  border: none;
  background: #def2ff;
  border-radius: 8px;
  color: $primary;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  padding: 5px 18px;
  transition: 0.3s ease;
  svg {
    margin-right: 8px;
    path {
      fill: $primary;
    }
  }
  &:hover {
    background: #99d4fa;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button--large {
  height: 63px;
  font-size: 16px;
  padding: 0 50px;
  @media (max-width: 400px) {
    height: 50px;
    padding: 0 40px;
  }
}

.button--small {
  height: 39px;
}

.button--back {
  background: transparent;
  border: none;
  color: $primary;
}
