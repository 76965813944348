.control {
  @apply mb-6;
}

.textArea {
  @apply w-full mb-2;
}

.label {
  @apply block w-full;
  @apply font-bold text-sm leading-normal;
  @apply text-gray-900;
}

.error {
  @apply block w-full;
  @apply text-sm text-right leading-none;
  @apply text-red-400 dark:text-red-500;
}

.radios {
  @apply mb-6;
  @apply grid gap-4 items-center;
  @apply border border-transparent rounded-xl;

  grid-template-columns: repeat(2, min-content);

  &:last-child {
    @apply mb-0;
  }

  &.vertical {
    @apply gap-0;
    grid-template-columns: 1fr;
  }

  &.center {
    @apply justify-center
  }

  &.left {
    @apply justify-start
  }

  &.hasError {
    @apply bg-red-100;

    .unchecked {
      @apply text-red-300;
    }
  }

  input {
    // Hiding the input completely disables focus tracking
    @apply absolute opacity-0;
  }

  label {
    @apply flex gap-4 items-center;
    @apply py-2 px-4 rounded-lg;
    @apply leading-none;
    @apply cursor-pointer select-none;
    @apply transition;

    .text {
      @apply flex-1;
      @apply text-base leading-normal;
      @apply text-gray-900;
    }

    .icons {
      @apply relative flex-none h-4 w-4;
    }

    .unchecked {
      @apply absolute;
      @apply text-gray-300;

      @apply opacity-100;
    }

    .checked {
      @apply absolute;
      @apply text-blue-500;

      @apply opacity-0;
    }
  }

  input:checked + label {
    .unchecked {
      @apply opacity-0;
    }

    .checked {
      @apply opacity-100;
    }
  }

  input:focus + label {
    @apply focus-ring;
  }
}

.details {
  @apply mt-1;
}
