.header.-isBoxshadow {
  box-shadow: 25px 25px 25px rgba(26, 26, 26, 0.06);
}

.Pricing {
  margin-top: 80px;
}

.PricingType {
  background: $black;
  @media (min-width: 1400px) {
    padding: 90px 0 130px;
  }
  padding: 7% 0 9%;
  &__inner {
    text-align: center;
    &__text {
      max-width: 565px;
      margin: 0 auto 60px;
      p {
        margin: 20px 0 40px;
      }
    }
    &__type {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      > div {
        width: calc(20% - 24px);
        min-width: 200px;
        border: 2px solid #0199f2;
        box-shadow: 0px 4px 50px rgba(26, 26, 26, 0.06);
        border-radius: 8px;
        margin: 0 12px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 16px;
      }
      h4 {
        margin-bottom: 2px;
      }
      p {
        &:nth-child(1),
        &:nth-child(3) {
          margin-bottom: 28px;
        }
      }
    }

    @media (max-width: 370px) {
      &__type {
        > div {
          padding: 16px 12px;
        }
        p {
          &:nth-child(1),
          &:nth-child(3) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.Questions {
  @media (min-width: 1400px) {
    padding: 90px 0 50px;
  }
  @media (max-width: 500px) {
    padding: 50px 0 20px;
  }
  padding: 7% 0 5%;
  &__inner {
    display: flex;
    h4 {
      width: 228px;
      margin: 30px 24px 24px;
    }
    .list-question {
      width: calc(100% - 324px);
      margin: 0 24px;
    }
    .CollapseQuestion {
      &:not(:last-child) {
        border-bottom: 2px solid #d7d9dd;
      }
      p {
        width: 90%;
        max-width: 615px;
      }
      &__button {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        &:focus {
          outline: none;
        }
        p {
          margin: 0;
        }
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: #16222f;
          }
        }
      }
      &__answer {
        p {
          margin-bottom: 30px;
        }
      }
    }
    @media (max-width: 800px) {
      display: block;
      h4,
      .list-question {
        width: calc(100% - 48px);
      }
    }
    @media (max-width: 500px) {
      h4,
      .list-question {
        width: calc(100% - 24px);
        margin: 0 12px;
      }
      .CollapseQuestion {
        &__button {
          padding: 16px 0;
        }
        &__answer {
          p {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
