//general
* {
  box-sizing: border-box;
}

// reset link style
a {
  display: inline-block;
  text-decoration: none;
  transition: 0.3s ease;
  color: $primary;
  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

//reset button style
button {
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

table {
  thead tr th {
    vertical-align: top !important;
  }
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
// v1 common css
/* common classes */
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 80px;
  @media (max-width: 600px) {
    padding: 0 20px;
  }
}

.triangle {
  height: 262px;
  @media (max-width: 1124px) {
    height: 140px;
  }
}

.dashboard--children {
  box-shadow: 0px 0px 25px 10px rgba(26, 26, 26, 0.06);
  border-radius: 8px;
}

.noData {
  color: #68717b;
}

.loading {
  display: flex;
  justify-content: center;
  margin: 7% 20px;
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.dashboard__item {
  min-height: calc(100vh - 48px);
  background: #f6f6f9;
  .Title {
    @include flex_parent_between();
    background: #ffffff;
    padding: 40px 28px;
    max-width: 1030px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 400px) {
      padding: 40px 16px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    h4 {
      margin: 0 15px 0 0;
    }
  }
  &__inner {
    max-width: 1030px;
    margin: 0 auto;
    padding: 24px 16px 7%;
    @media (max-width: 400px) {
      padding: 24px 4px;
    }
  }
}

.--noData {
  font-size: 12px;
  line-height: 16px;
  color: $gray03;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 11px;
  }
}

.error-message {
  color: #f7553f;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  margin-top: 0.25rem;
}

.--header {
  background: #ffffff;
  padding: 40px 28px;
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 400px) {
    padding: 40px 16px;
  }
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5000px;
    background: white;
  }
  &::after {
    left: 100%;
  }
  &::before {
    right: 100%;
  }
  a {
    margin-bottom: 16px;
    svg {
      margin-right: 8px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.__noData {
  color: $gray03;
  margin: 12px;
}

.mg-b-0 {
  margin-bottom: 0 !important;
}
