.Modal.Modal__send__survey {
  max-width: 600px;
  margin: 0 auto;
  .modal-content {
    padding: 80px 50px;
  }
  @media (max-width: 500px) {
    .modal-content {
      padding: 60px 16px;
    }
  }
}
