.Student {
  .Header-student {
    background: #ffffff;
    padding: 40px 28px 0;
    max-width: 1030px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 400px) {
      padding: 40px 16px 0;
    }
    .notification {
      margin-right: 4px;
      color: #ffbc42;
    }
    &__title {
      @include flex_parent_between();
      flex-wrap: wrap;
      h4 {
        margin: 0 0 12px;
      }
      > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .button-back {
        margin: 0 15px 12px 0;
        svg {
          margin-right: 10px;
        }
      }
      .Breadcrumb {
        flex-wrap: wrap;
        margin-bottom: 12px;
      }
      @media (max-width: 785px) {
        display: block;
        h4 {
          margin-bottom: 10px;
        }
      }
      @media (max-width: 620px) {
        > div {
          display: block;
        }
        .button-back {
          margin: 0 0 12px 0;
        }
      }
      @media (max-width: 370px) {
        .button-back {
          font-size: 12px;
          padding: 5px 10px;
        }
      }
      .report-name {
        text-transform: uppercase;
      }
    }
    &__taps {
      display: flex;
      justify-content: space-between;
      width: 100%;
      a {
        color: #68717b;
        padding: 16px 0;
        transition: 0.3s ease;
        border-bottom: 2px solid transparent;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &.isActive {
          color: #16222f;
          border-color: #ffbc42;
        }
        &:hover {
          color: #16222f;
          border-color: #ffbc42;
          text-decoration: none;
        }
      }
      @media (max-width: 1090px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        a {
          width: auto;
          padding: 16px 16px 16px 0;
        }
      }
      @media (max-width: 600px) {
        a {
          padding: 8px 8px 8px 0;
        }
      }
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    &::after {
      left: 100%;
    }
    &::before {
      right: 100%;
    }
  }
}

.General-student {
  &__inner {
    display: flex;
    justify-content: space-between;
    .info,
    button {
      margin: 8px 12px;
    }
    .info {
      width: calc(100% - 186px);
      max-width: 540px;
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        p {
          margin: 0;
          &:first-child {
            width: 45%;
            max-width: 170px;
          }
          &:last-child {
            width: 51%;
          }
        }
      }
    }
    &__button {
      display: flex;
      @media (max-width: 900px) and (min-width: 766px) {
        display: block;
      }
      @media (max-width: 350px) {
        display: block;
      }
    }
    button {
      width: 145px;
    }
    @media (max-width: 765px) {
      flex-direction: column;
      .info {
        width: calc(100% - 24px);
      }
    }
    @media (max-width: 600px) {
      .info {
        width: calc(100% - 24px);
        > div {
          margin-bottom: 20px;
        }
        img {
          width: 32px;
        }
      }
    }
  }
}

.Student-tap {
  .button__add {
    text-align: right;
    margin: 0 12px 26px;
    .button--icon {
      margin: 0 0 0 auto;
    }
  }
}

.Student-card {
  background: #ffffff;
  border-radius: 8px;
  margin: 0 12px 16px;
  padding: 16px 24px;
  position: relative;
  p {
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  &__inner {
    overflow: auto;
  }
  .Table {
    margin: 0;
    padding: 0;
    table {
      thead tr {
        background: #ffffff;
        th {
          color: #989ea5;
          svg path {
            fill: black;
          }
        }
      }
      tr {
        th,
        td {
          padding: 5px 12px 5px 0;
          text-transform: capitalize;
          &:first-child {
            min-width: auto;
          }
        }
        td:last-child {
          position: relative;
          bottom: 2px;
        }
      }
      .document {
        color: $primary;
        cursor: pointer;
        display: block;
        svg {
          margin-right: 12px;
        }
      }
    }
  }
  &__status {
    display: inline-flex;
    align-items: center;
    justify-self: center;
    padding: 4px 12px 5px;
    border-radius: 16px;
    background-color: #39a739;
    @include position(absolute, 12px 12px null null);
    &.Student-card__status--pending {
      background-color: #f4ad2b;
    }
    &.Student-card__status--pending_verification {
      background-color: #e75555;
    }
    svg {
      height: 16px;
      margin: 0 6px 0 0;
      fill: #fff;
    }
    span {
      color: #fff;
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media (max-width: 600px) {
  }
}

.Enrollment {
  .Student-card {
    &__inner {
      > div {
        min-width: 800px;
        @media (max-width: 600px) {
          min-width: 600px;
        }
        .TableWrapper:first-child {
          tr {
            th,
            td {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                width: 13%;
              }
            }
          }
        }
        .TableWrapper:last-child {
          tr {
            th,
            td {
              &:nth-child(1) {
                width: 39%;
              }
            }
          }
        }
      }
    }
  }
}

.Financial {
  .Student-card {
    &__inner {
      > div {
        min-width: 700px;
        @media (max-width: 600px) {
          min-width: 570px;
        }
      }
    }
  }
}

.Placement {
  .Placement__item {
    cursor: pointer;
  }
  .Student-card {
    .actions-with-notifications {
      position: relative;
      display: grid;
      grid-template-columns: 20px 1fr;
      align-items: center;
    }
    .notifications {
      padding-top: 3px;
      font-size: 20px;
      color: #ffbc42;
      vertical-align: middle;
    }
  }
}

.AddPlacement {
  .checkbox-group {
    max-width: 538px;
    .Code__item {
      margin-bottom: 10px;
    }
  }
  .employment-attribute {
    > div {
      max-width: 538px;
      input,
      textarea {
        max-width: 300px;
      }
    }
  }
}

.ContactLog {
  .Student-card {
    &__inner {
      > div {
        min-width: 660px;
        @media (max-width: 600px) {
          min-width: 600px;
        }
        .TableWrapper {
          tr {
            th,
            td {
              &:first-child {
                width: 25%;
              }
            }
          }
        }
      }
    }
  }
}

.Document {
  &__inner {
    margin: 6px 2px;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
    border-radius: 8px;
    width: 228px;
    margin: 0 10px 16px;
    padding: 20px 16px;
    svg {
      width: 33px;
      height: 28px;
      margin-bottom: 35px;
    }
    .headline {
      font-size: 14px;
      line-height: 19px;
      margin: 0 0 4px;
      font-weight: 600;
      word-wrap: break-word;
    }
    .info {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: $black;
    }
    &:hover {
      text-decoration: inherit;
    }
    @media (max-width: 750px) {
      width: calc(50% - 20px);
      padding: 15px 10px;
      svg {
        width: 25px;
        height: 22px;
        margin-bottom: 20px;
      }
      .headline {
        font-size: 12px;
        line-height: 16px;
      }
      .info {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
}

.StudentTasks {
  max-width: 1200px;
  height: calc(100vh - 48px);
  padding: 24px 16px;

  .admin-board-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: auto;
    gap: 16px;
  }

  .admin-board-column {
    width: 280px;
    margin: 0;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__inner {
      max-height: 100%;
      background: #c1d4e8;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 0 0 8px;
    }

    &__header {
      padding: 12px 20px 8px;
      margin: 0 0 4px;
      h6 {
        line-height: 20px;
        margin: 0;
        text-transform: capitalize;
      }
    }

    ul {
      padding: 0 8px !important;
      margin: 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      li {
        padding: 12px 12px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1px 1px #091e4240;
        margin: 0 0 8px;
        h6 {
          font-size: 14px;
          display: block;
          cursor: pointer;
          margin: 0;
          color: var(--bs-table-striped-color);
          &:hover {
            color: $primary;
          }
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin: 0;
        }

        button {
          padding: 4px 16px;
          background: #0199f2;
          color: white;
          font-size: 12px;
          float: right;
          margin: 4px 0 0;
          &:hover {
            background: #3bacf4;
          }
        }
      }
    }
  }
}
