.SurveyResults {
  &__error {
    margin: 40px;
    padding: 20px;
    text-align: center;
    color: #dc3545;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      fill: #dc3545;
    }
  }

  .filter {
    display: flex;
    margin: 0 12px 8px;
    flex-wrap: wrap;
    > div,
    input {
      width: calc(50% - 24px);
      max-width: 303px;
      margin: 0 24px 12px 0;
      min-width: 188px;
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  table {
    min-width: 400px;
    tr {
      td:first-child {
        width: 20%;
      }
      td:last-child {
        width: 30%;
      }
      td {
        .document {
          text-align: left;
          color: $primary;
          cursor: pointer;
          display: block;
          white-space: nowrap;
          svg {
            margin-right: 6px;
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}
