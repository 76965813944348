// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  .modal.show & {
    transform: none;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: scale(1.02);
  }
}

.modal-dialog-scrollable {
  height: subtract(100%, 0.5rem * 2);

  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, 0.5rem * 2);
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: null;
  pointer-events: auto;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid rgba($black, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: $black;

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: 0.5;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: 1rem;
  border-bottom: 1rem solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  .btn-close {
    padding: (1rem * 0.5) (1rem * 0.5);
    margin: (1rem * -0.5) (1rem * -0.5) (1rem * -0.5) auto;
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: 1rem;
}

// Footer (for actions)
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 1rem - 0.5rem * 0.5;
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: 0.5rem 0.5;
  }
}

// // Scale up the modal
// @include media-breakpoint-up(sm) {
//   // Automatically set modal's width for larger viewports
//   .modal-dialog {
//     max-width: $modal-md;
//     margin: $modal-dialog-margin-y-sm-up auto;
//   }

//   .modal-dialog-scrollable {
//     height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
//   }

//   .modal-dialog-centered {
//     min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
//   }

//   .modal-content {
//     @include box-shadow($modal-content-box-shadow-sm-up);
//   }

//   .modal-sm { max-width: $modal-sm; }
// }

// @include media-breakpoint-up(lg) {
//   .modal-lg,
//   .modal-xl {
//     max-width: $modal-lg;
//   }
// }

// @include media-breakpoint-up(xl) {
//   .modal-xl { max-width: $modal-xl; }
// }

// // scss-docs-start modal-fullscreen-loop
// @each $breakpoint in map-keys($grid-breakpoints) {
//   $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//   $postfix: if($infix != "", $infix + "-down", "");

//   @include media-breakpoint-down($breakpoint) {
//     .modal-fullscreen#{$postfix} {
//       width: 100vw;
//       max-width: none;
//       height: 100%;
//       margin: 0;

//       .modal-content {
//         height: 100%;
//         border: 0;
//         @include border-radius(0);
//       }

//       .modal-header {
//         @include border-radius(0);
//       }

//       .modal-body {
//         overflow-y: auto;
//       }

//       .modal-footer {
//         @include border-radius(0);
//       }
//     }
//   }
// }
// // scss-docs-end modal-fullscreen-loop

.modal {
  padding: 0 !important;
  z-index: 1070;
}
.modal-backdrop {
  z-index: 1065;
}
.wrap-modal {
  background: #172638;
  opacity: 0.5;
}
.Modal {
  max-width: 760px;
  margin: 50px auto;
  padding: 0 20px;
  .modal-content {
    background: #ffffff;
    border-radius: 8px;
    border: none;
    padding: 20px 20px;
  }
  .modal-header,
  .modal-footer,
  .modal-body {
    padding: 0;
  }
  .modal-header,
  .modal-footer {
    border: none;
    justify-content: center;
  }
  .modal-header .modal-title {
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;
  }
  .modal-body {
    margin: 0 auto 24px;
    width: 100%;
  }
  .modal-footer {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    button {
      margin: 0;
      width: 50%;
    }
  }
  @media (max-width: 500px) {
    padding: 0 16px;
    .modal-content {
      padding: 60px 16px;
    }
  }
}

.Modal__add-code {
  max-width: 840px;
  .modal-body {
    max-width: 660px;
    .input__search {
      .form-group {
        margin-bottom: 24px;
      }
    }
  }
  .list-code {
    background: #f6f6f9;
    border-radius: 8px;
    padding: 16px 24px;
    max-height: 52vh;
    min-height: 300px;
    overflow: auto;
    @media (max-width: 1400px) {
      max-height: 40vh;
    }
  }
  .Code--collapse:not(:last-child) {
    margin-bottom: 16px;
  }
  .btn-collape {
    display: flex;
    svg {
      margin: 0 12px 7px 0;
      path {
        fill: $primary;
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
      margin: 0;
      color: $primary;
    }
    &:focus {
      outline: none;
    }
  }
  .collapse__parent {
    padding: 16px 0 0 9px;
    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  .collapse__children {
    padding: 16px 0 0 30px;
    .text--title {
      font-size: 14px;
      line-height: 19px;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .form-group {
      margin-bottom: 5px;
    }
  }
  .Code__item {
    margin-bottom: 12px;
    .checkbox button {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .list-code {
      padding: 16px;
    }
    .btn-collape p {
      font-size: 12px;
    }
    .collapse__parent {
      padding-top: 5px;
    }
    .collapse__children {
      padding-top: 5px;
      .text--title {
        font-size: 12px;
        margin-bottom: 12px;
      }
      &__item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      .form-group {
        margin-bottom: 3px;
      }
      .checkbox {
        font-size: 12px;
      }
    }
  }
}

.Modal__Payment {
  &__icon__warning {
    width: 48px;
    height: 48px;
    line-height: 46px;
    text-align: center;
    font-size: 24px;
    color: $alert01;
    border: 2px solid $alert01;
    border-radius: 100%;
    margin: 4px auto 6px;
  }
}

.Modal__task-detail {
  .modal-content {
    padding: 0;
  }
  .modal-header {
    height: 100px;
    background-color: $black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .btn-close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 16px;
      top: 16px;
      border-radius: 50%;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  .modal-body {
    padding: 20px;
    .section-1,
    .section-2,
    .section-3 {
      margin-top: 1rem;
    }
    .section-1 {
      &--items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 24px;
      }
      &--item {
        margin-bottom: 8px;
        & > p:nth-child(2) {
          margin: 10px 0 0;
        }
      }
      .title--item {
        color: $gray03;
        margin-bottom: 0.2rem;
      }
    }
    .section-content {
      padding: 0 2rem;
    }
    .description {
      padding-left: 0;
      .placement {
        &-details {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          padding-left: 1rem;
          p {
            margin: 0;
          }
        }
      }
    }
    .title {
      position: relative;
      padding-left: 32px;
      svg {
        @include absolute_centered(y);
        left: 0;
      }
    }
    .avatar-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .avatar {
      width: 2rem;
      height: 2rem;
      background-color: $primary;
      color: $white;
      border-radius: 50%;
      @include flex_parent_centered;
    }
    .tooltip-content {
      z-index: 999;
      background-color: $black;
      color: $white;
      border-radius: 4px;
      padding: 0.5rem;
      font-size: 0.875rem;
    }
    .attachments--item {
      display: flex;
      gap: 0.5rem;
      margin: 0 0 8px;
      svg {
        position: relative;
        top: 1px;
      }
      span {
        flex: 1;
        word-break: break-all;
      }
    }
    svg {
      vertical-align: -0.125rem;
    }
    .status--pending {
      color: $warning01;
    }
    .status--completed {
      color: $success01;
    }
    .status--in_review {
      color: $primary01;
    }
    .status--rejected {
      color: $alert01;
    }
  }
  .modal-footer {
    gap: 4px;
    padding: 0 0 24px;
    button {
      width: 45%;
    }
  }
}

.Modal__placement-detail {
  .modal-header .btn-close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .modal-body {
  }
}
