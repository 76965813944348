.Login {
  form {
    max-width: 452px;
    padding: 40px 32px 24px;
  }
  .form__inner {
    padding: 24px 8px;
    button {
      width: 100%;
    }
    .remember-me {
      @include flex_parent_between();
      margin: 25px 0;
      .checkbox {
        font-size: 12px;
      }
    }
  }
  .button-group {
    margin: 0 0 30px;
    @include flex_parent_centered();
    flex-wrap: wrap;
    text-align: center;
    button {
      margin: 0 8px 10px;
      min-width: 178px;
      padding: 0 21px;
    }
    p {
      margin: 0;
    }
  }
  @media (max-width: 500px) {
    form {
      padding: 30px 10px 14px;
    }
    .form__inner {
      padding: 16px 8px 14px;
    }
    .button-group {
      margin: 0 8px 15px;
      button {
        margin: 0 0 10px;
        width: 100%;
      }
    }
  }
  @media (max-width: 400px) {
    .form__inner {
      .remember-me {
        flex-direction: column;
        margin: 16px 0;
      }
    }
  }
}
