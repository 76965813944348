@mixin rowFormat {
  color: #244c7c;
  font-size: 16px;
  padding: 10px 16px;
  background: #ffffff;
  @media (max-width: 900px) {
    font-size: 14px;
  }
}
@mixin rowTitle {
  color: #244c7c;
  font-size: 16px;
  padding: 10px 16px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
}

.reporting {
  min-height: 100%;
  background: #f6f6f9;
  .HeaderReporting {
    padding: 40px 16px 0;
    max-width: 1030px;
    margin: 0 auto;
    background: white;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 5000px;
      background: white;
    }

    @media (max-width: 400px) {
      padding: 30px 4px 0;
    }
    .title {
      margin: 0 12px 24px;
      h4 {
        text-transform: uppercase;
      }
    }
    .list-filter {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: calc(25% - 24px);
        margin: 0 12px 24px;
        .dropdown-toggle {
          color: $gray02;
        }
      }
      @media (max-width: 1110px) {
        > div {
          width: calc(50% - 24px);
        }
      }
      @media (max-width: 400px) {
        flex-direction: column;
        > div {
          width: calc(100% - 24px);
          margin: 0 12px 16px;
        }
      }
    }
    .taps {
      @include flex_parent_between();
      margin: 0 12px;
      &__left {
        display: flex;
        justify-content: space-between;
        max-width: 200px;
        width: calc(100% - 126px);
        a {
          color: $gray03;
          padding: 16px 0;
          margin-right: 10px;
          transition: 0.3s ease;
          border-bottom: 2px solid transparent;
          &:hover {
            color: $black;
            border-color: #ffbc42;
            text-decoration: none;
          }
        }
        .isActive {
          color: $black;
          border-color: #ffbc42;
        }
      }
      &__right {
        .dropdown-toggle {
          border: none;
          padding: 3px 16px;

          p {
            margin: 0 10px;
          }
          svg path {
            fill: $primary;
          }
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          width: 216px;
          @media (max-width: 1000px) {
            width: 190px;
            .dropdown-item {
              padding: 4px 16px;
              font-size: 12px;
            }
          }
        }
        .Loading {
          padding: 0;
        }
      }
      @media (max-width: 340px) {
        &__left {
          width: calc(100% - 69px);
        }
        &__right {
          .dropdown-toggle {
            padding: 3px 0px;
            p {
              margin: 0px 2px;
            }
          }
        }
      }
    }
  }
}

.CoverSheet {
  &__inner {
    margin: 0 12px;
    .info {
      margin-bottom: 50px;
    }
    .title {
      margin: 15px 0 8px;
    }
    .text {
      margin-bottom: 32px;
    }
    .list__item {
      display: flex;
      flex-wrap: wrap;
      > div {
        display: flex;
        width: 50%;
        p:first-child {
          width: 170px;
          margin-right: 20px;
        }
      }
      @media (max-width: 1000px) {
        > div {
          p:first-child {
            margin-right: 10px;
          }
        }
      }
      @media (max-width: 830px) {
        display: block;
        > div {
          width: 100%;
          p:first-child {
            width: 110px;
          }
        }
      }
    }
    @media (max-width: 600px) {
      .info {
        margin-bottom: 35px;
      }
      .text {
        margin-bottom: 24px;
      }
      .list__item {
        display: block;
        > div p {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.reporting-collape {
  border-radius: 8px;
  background: #ffffff;
  margin-bottom: 12px;
  .btn-collape {
    @include flex_parent_between();
    background: #def2ff;
    padding: 24px 25px;
    p {
      margin: 0;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: $black;
      }
    }
    &:focus {
      outline: none;
    }
  }
  .Table {
    padding: 24px 10px 20px;
  }
  table {
    min-width: 400px;
    tr {
      th,
      td {
        &:last-child {
          padding-right: 30px !important;
        }
        &:nth-child(2),
        &:last-child {
          width: 140px;
          text-align: right;
        }
        &:nth-child(2) {
          padding-right: 15px !important;
        }
        &:first-child {
          min-width: 200px;
        }
      }
    }
  }
  .Table-dark {
    thead {
      tr {
        background: #ffffff;
        th {
          color: #68717b;
          font-size: 10px;
          font-weight: 600;
          padding: 0px 0 5px 12px;
        }
      }
    }
    tbody {
      tr {
        background: #f6f6f9;
        margin-bottom: 4px;
        td {
          border: none;
          padding: 5.5px 16px;
          font-size: 14px;
          font-weight: 600;
          border-bottom: 4px solid white;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
        }
        &.--tableFormat {
          td {
            &:nth-child(2),
            &:nth-child(3) {
              width: 30%;
              text-align: left;
            }
          }
          &:first-child {
            background: #ffffff;
            td {
              color: #68717b;
              font-size: 10px;
              font-weight: 600;
            }
          }
          &:last-child {
            td {
              @include rowFormat();
            }
          }
        }
        &.tableEmployment {
          &:nth-child(1),
          &:nth-child(5),
          &:nth-child(8),
          &:nth-child(10) {
            td {
              color: #244c7c;
              font-size: 16px;
              padding: 10px 16px;
            }
          }
        }
        &.--tableTitle {
          &:last-child {
            td {
              @include rowFormat();
            }
          }
        }
        &.--tableFinancial {
          &:last-child,
          &:nth-child(8) {
            td {
              @include rowFormat();
            }
          }
        }
        &.--tableSalary {
          &:first-child {
            td {
              @include rowTitle();
            }
          }
        }
        &.--tableEmployment {
          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(10),
          &:nth-child(11) {
            td {
              @include rowTitle();
            }
          }
        }
        &.tableBPSS,
        &.tableBPSSGraduation {
          &:last-child {
            td {
              @include rowFormat();
            }
          }
          &:first-child {
            background: #ffffff;
            td {
              color: #68717b;
              font-size: 10px;
              font-weight: 600;
            }
          }
        }
        &.tableBPSS {
          td:not(:first-child) {
            width: 120px;
            text-align: right;
            @media (max-width: 900px) {
              width: 80px;
            }
          }
        }
        &.tableBPSSGraduation {
          td:not(:first-child) {
            width: 93px;
            text-align: right;
          }
        }
        &.tableDPOS {
          &:first-child {
            background: #ffffff;
            td {
              color: #68717b;
              font-size: 10px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .btn-collape {
      padding: 16px 20px;
    }
    .Table {
      padding: 20px 10px;
    }
    .Table-dark {
      tbody {
        tr {
          td {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    .Table__wrap {
      overflow-x: auto;
    }
  }
  @media (max-width: 500px) {
    .Table {
      padding: 16px 5px;
    }
    table {
      tr td:last-child {
        padding-right: 16px;
      }
    }
    .Table-dark {
      tr {
        td {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
}

.StudentList {
  &__inner {
    padding: 8px 0;
    .filter {
      display: flex;
      > div {
        width: calc(33.33% - 24px);
        margin: 0 12px 21px;

        .dropdown-toggle {
          height: 40px;
          font-size: 12px;
        }
        .dropdown-item {
          font-size: 12px;
        }
      }
      @media (max-width: 900px) {
        display: block;
        > div {
          width: calc(100% - 24px);
          margin: 0 12px 16px;
        }
      }
    }
    .Table__inner {
      max-height: 500px;
      border-radius: 8px 8px 0 0;
    }
    .--nowrap {
      tr td {
        white-space: nowrap;
      }
    }
    table {
      min-width: 735px;
      tr {
        background: none !important;
        th,
        td {
          min-width: 150px;
          max-width: 200px;
          &:first-child {
            @include position(sticky, null null null 0);
            z-index: 1;
            border-radius: 0;
          }
          &:last-child {
            @include position(sticky, null 0 null null);
            z-index: 1;
            border-radius: 0;
            min-width: 44px;
          }
        }
        th {
          font-size: 10px;
          line-height: 14px;
          padding: 6px 0 6px 12px;
          position: sticky;
          z-index: 2 !important;
          top: 0;
          background: #172638;
          &:first-child {
            z-index: 3 !important;
          }
        }
        &:nth-of-type(odd) td {
          background-color: #f6f6f9;
        }
        td {
          text-transform: capitalize;
          width: 12%;
          background-color: white;
          &:last-child {
            border-left: 1px solid #e7e7e7;
            svg {
              path {
                fill: $primary;
              }
            }
          }
          &:first-child {
            border-right: 1px solid #e7e7e7;
          }
        }
      }
    }
  }
  .total {
    margin: 0 25px;
    font-size: 12px;
    line-height: 14px;
  }
  .button-edit-student {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}

.ChangeLog {
  &__inner {
    padding: 8px 0;
    .filter {
      display: flex;
      > div {
        width: calc(33.33% - 24px);
        margin: 0 12px 30px;
        .dropdown-toggle {
          height: 40px;
          font-size: 12px;
          line-height: 16px;
        }
        .dropdown-item {
          font-size: 12px;
          line-height: 18px;
        }
        &:last-child {
          margin-bottom: 30px;
        }
      }
      .Date-picker__input {
        width: calc(33.33% - 24px);
        margin: 0 12px 30px;
        font-size: 12px;
        line-height: 16px;
      }

      @media (max-width: 950px) {
        flex-wrap: wrap;
        > div,
        .Date-picker__input {
          width: calc(50% - 24px) !important;
          margin: 0 12px 16px;
        }
      }
      @media (max-width: 700px) {
        > div,
        .Date-picker__input {
          width: calc(100% - 24px) !important;
          margin: 0 12px 16px;
        }
      }
    }
    .list_change {
      background: #ffffff;
      border-radius: 8px;
      position: relative;
      margin: 0 12px 30px;
      .tag {
        border-radius: 8px;
        color: #ffffff;
        padding: 4px 13px;
        font-size: 14px;
        line-height: 19px;
        position: absolute;
        top: -13.5px;
        left: 24px;
      }
      .tag.today {
        background: $primary !important;
      }
      .wrap__table {
        padding: 25px 24px;
        > div {
          overflow-x: auto;
        }
      }
      table {
        min-width: 770px;
        margin-bottom: 0;
        tr {
          td {
            vertical-align: middle;
            border: none;
            font-size: 14px;
            line-height: 19px;
            padding: 11px 16px 11px 0;
            font-weight: 600;
            &:last-child {
              padding: 11px 6px 11px 0;
              text-align: right;
            }
            svg {
              width: 20px;
              height: 20px;
              path {
                fill: $black;
              }
            }
          }
        }
        .name {
          p {
            display: flex;
            align-items: center;
            margin: 0;
            font-weight: 700;
            span {
              @include flex_parent_centered();
              background: #76cb93;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              text-transform: uppercase;
              margin-right: 8px;
            }
          }
        }
      }
      @media (max-width: 1200px) {
        table {
          tr {
            td {
              font-size: 12px;
              line-height: 16px;
              padding: 5px 16px 5px 0;
              &:last-child {
                padding: 5px 6px 5px 0;
                width: 128px;
              }
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
          .name {
            p {
              span {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
      @media (max-width: 600px) {
        table {
          min-width: 630px;
          tr {
            td {
              font-size: 10px;
              line-height: 14px;
              padding: 3px 16px 3px 0;
              &:last-child {
                padding: 3px 5px 3px 0;
                width: 108px;
              }
              svg {
                width: 18px;
                height: 18px;
              }
            }
            .name p span {
              width: 25px;
              height: 25px;
              font-size: 11px;
              line-height: 14px;
            }
          }
          .name {
            p {
              span {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
    .button__see-more {
      text-align: center;
    }
  }
}

.is-loading {
  &__overlay {
    position: fixed;
    width: calc(100% - 208px);
    height: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.38);
    z-index: 999;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
