@mixin sidebarSmall {
  width: 72px;
  transition: 0.3s ease;
  .Sidebar__inner {
    .logo svg {
      margin-left: 5px;
      path {
        display: none;
      }
      .logoMobie {
        display: block;
      }
    }

    .sidebar__item {
      background: transparent;
      p {
        display: none;
      }
    }
    .Reporting {
      position: relative;
    }
    .list-reporting {
      position: absolute;
      top: 6px;
      left: 57px;
      padding: 20px;
      background: $black;
      z-index: 999;
      height: 0;
      border-radius: 6px;
      transition: 0.3s ease;
      p {
        padding: 4px 12px;
        width: 96px;
      }
    }
  }
}

@mixin icon-hover {
  background: #3c4854;
  svg {
    rect {
      stroke: $primary;
    }
    path {
      stroke: $primary;
    }
  }
  .reporting-icon {
    svg {
      rect:last-child {
        fill: $primary;
      }
    }
  }
  .iconArrow svg path {
    fill: $primary;
    stroke: transparent;
  }
  .survey-icon svg path {
    fill: $primary;
    stroke: transparent;
  }
  .student-icon,
  .cohort-icon svg {
    rect {
      fill: $primary;
    }
    circle {
      stroke: $primary;
    }
  }
  .program-icon svg {
    rect:not(:first-child) {
      fill: $primary;
    }
  }
  .tasks-icon svg {
    color: $primary;
  }
  .financial-aid-programs-icon svg {
    path {
      fill: $primary;
    }
  }
  .branch-icon svg {
    path {
      fill: $primary;
    }
  }
  .stitution-icon svg {
    > rect:not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(6)) {
      fill: $primary;
    }
  }
  p {
    color: $primary;
  }
}

.Sidebar {
  width: 208px;
  background: $black;
  height: 100vh;
  overflow: hidden auto;
  transition: 0.3s ease;
  flex-shrink: 0;

  @media (max-width: 600px) {
    @include position(fixed, 0 null 0 0);
    transform: translateX(-100%);
    z-index: 6;
  }

  &__inner {
    padding: 24px 16px;
    .logo {
      svg {
        height: 26px;
        margin-bottom: 60px;
      }
    }
    .sidebar__item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      svg {
        margin-right: 8px;
        margin-bottom: 3px;
        width: 30px;
      }
      p {
        color: $white;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
      }
      .tasks-icon svg {
        color: $white;
        @include size(30px 22px);
      }
      &:hover {
        @include icon-hover();
        text-decoration: none;
      }
    }
    .isActive {
      @include icon-hover();
    }
    .Reporting {
      .sidebar__item {
        justify-content: space-between;
        align-items: center;
        &:focus {
          outline: none;
        }
      }
      .title {
        display: flex;
      }
      .list-reporting {
        padding: 0 0 0 30px;
        color: $white;
        a {
          color: $white;
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 5px;
          padding: 4px 0 4px 12px;
          border-radius: 6px;
          cursor: pointer;
          &:hover {
            background: $gray03;
            text-decoration: none;
          }
        }
      }
      .isActive {
        svg path {
          fill: $primary;
        }
      }
      .isActiveItem {
        background: $gray03;
      }
    }
  }
}

.Dashboard {
  background: $white;
  display: flex;
  position: relative;
  z-index: 1;

  &.--toggle {
    @media (max-width: 600px) {
      .Dashboard__overlay {
        display: block;
      }
      .Sidebar {
        transform: translateX(0);
      }
    }
    @media (min-width: 601px) {
      .Sidebar {
        @include sidebarSmall();
      }
    }
  }

  &__inner {
    width: 100%;
    transition: 0.3s ease;
    overflow: hidden;
  }

  &__overlay {
    display: none;
    @include position(fixed, 0 0 0 0);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.721569);
    transition: 0.3s ease;
    button {
      @include position(absolute, 16px 16px null null);
      background-color: transparent;
      border: 0;
      @include size(32px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      transition: 0.25s linear;
      padding: 0;
      margin: 0;
      &:hover {
        color: $primary;
      }
    }
  }
}

.Header-dashboard {
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
  position: relative;
  z-index: 4;
  &__inner {
    @include flex_parent_between();
    padding: 8px 10px 8px 20px;
    @media (max-width: 600px) {
      padding: 8px 15px;
    }
    &__left {
      transition: 0.3s ease;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover svg path {
        fill: $primary;
      }
    }
    &__right {
      @include flex_parent_centered();
      .bt-icon {
        @include flex_parent_centered();
        padding: 6px 12px;
        background: #f0f1f2;
        border-radius: 8px;
        margin-right: 8px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover svg path {
          fill: $primary;
        }
      }
      .Dropdown .dropdown-toggle {
        font-weight: 600;
        background: #f0f1f2;
        border: none;
        width: 142px;
        min-width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding-right: 27px;
        height: 32px;
        padding: 8px 27px 8px 12px;
        &::after {
          background: url(../../assets/icons/down.svg) no-repeat center/20px;
          position: absolute;
          top: 7px;
          right: 8px;
        }
      }
      .dropdown-item {
        color: $primary !important;
        font-weight: 600;
        svg {
          margin-right: 8px;
          path {
            fill: $primary;
          }
        }
      }
    }
    @media (max-width: 400px) {
      padding: 8px 10px 8px 10px;
      &__right {
        .bt-icon {
          padding: 6px 6px;
        }
        .Dropdown--light .dropdown-toggle {
          width: 110px;
        }
      }
    }
  }
}
