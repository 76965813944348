.Integrations {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    &__item {
      box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
      border-radius: 8px;
      background: #ffffff;
      padding: 20px 12px 20px 20px;
      margin: 0 12px 24px;
      max-width: 308px;
      min-width: 280px;
      width: 100%;
      &__info {
        padding-right: 8px;
      }
      .action {
        text-align: right;
        width: 37px;
        margin: 0 0 0 auto;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: #828282;
          }
        }
      }
      .logo {
        @include flex_parent_between();
        margin-bottom: 10px;
        .installed {
          display: flex;
          svg {
            @include size(18px);
            margin-right: 14px;
            path {
              fill: $primary;
            }
          }
          p {
            margin: 0;
          }
        }
      }
      img {
        height: 54px;
        max-width: 115px;
      }
      .title {
        margin-bottom: 8px;
      }
      .time {
        margin: 0;
        color: $gray03;
      }
    }
  }
}

.Modal__message__data-sync {
  margin: 1.75rem auto;
  .modal-title {
    max-width: 400px;
  }
}
.Modal__message__integrate {
  .modal-header {
    align-items: center;
  }
  .modal-body {
    margin: 24px auto;
    max-width: 590px;
    text-align: center;
  }
}

.DataSync {
  .dataSync {
    padding: 40px 16px 7%;
    &__inner {
      margin: 0 12px;
      &__item {
        margin-bottom: 40px;
        width: 100%;
        max-width: 567px;
        &:last-child {
          max-width: 608px;
        }
        p {
          margin-bottom: 8px;
        }
      }
      &__status {
        background: #ffffff;
        box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
        border-radius: 8px;
        padding: 22px 24px;
        &__info {
          @include flex_parent_between();
          flex-wrap: wrap;
          p:last-child {
            cursor: pointer;
            min-width: 215px;
          }
          p:first-child {
            min-width: 130px;
          }
        }
        &__progress {
          width: 100%;
          height: 4px;
          background: $alert02;
          border-radius: 3px;
          margin-bottom: 22px;
          span {
            height: 4px;
            background: $primary;
            display: block;
            width: 0;
            transition: 2s ease;
          }
        }
        &__note {
          @include flex_parent_between();
          &__percent {
            display: flex;
            > div {
              display: flex;
              align-items: center;
              &:first-child {
                margin-right: 32px;
              }
              span {
                @include size(24px);
                border-radius: 8px;
                margin-right: 16px;
              }
              p {
                margin: 0;
              }
            }
          }
          &__date {
            p {
              color: $gray03;
              margin: 0;
            }
          }

          @media (max-width: 380px) {
            display: block;
            > div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .button-group {
      max-width: 400px;
      margin: 32px 0;
      width: 100%;
      button {
        width: 50%;
        max-width: inherit;
      }
    }
  }
}

.Instructions {
  &__header {
    h4 {
      margin-bottom: 16px;
    }
    .--note {
      background: #def2ff;
      border-radius: 5px;
      padding: 8px 20px;
      width: fit-content;
    }
    p:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.list-types {
  padding-top: 45px;
  &__inner > p {
    margin: 0 12px 24px;
  }
  .type-of-import {
    display: flex;
    justify-content: space-between;
    > div {
      background: #ffffff;
      box-shadow: 0px 10px 25px rgba(26, 26, 26, 0.06);
      border-radius: 8px;
      margin: 0 12px 16px;
      width: calc(50% - 24px);
      padding: 30px 24px 45px;
      text-align: center;
      transition: 0.3s ease;
      border: 1.5px solid transparent;
      cursor: pointer;
      &:hover,
      &:focus,
      &.active {
        border-color: #0199f2;
        outline: none;
      }
      > div {
        flex-direction: column;
        justify-content: space-between;
        display: flex;
      }
      img {
        height: 117px;
        margin-bottom: 15px;
      }
      .title {
        margin: 10px 0 8px;
      }
    }
    @media (max-width: 750px) {
      display: block;
      > div {
        width: calc(100% - 24px);
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          width: 130px;
        }
        > div {
          text-align: left;
        }
      }
    }
    @media (max-width: 360px) {
      > div {
        padding: 16px;
        img {
          width: 100px;
        }
      }
    }
  }
}

.PullingData {
  &__header {
    h4 {
      margin-bottom: 16px;
    }
  }
  .Step-by-step {
    padding-top: 35px;
    .title {
      margin: 0 12px 10px;
    }
    &__inner {
      > div {
        @include flex_parent_between();
        > div {
          margin: 0 12px;
        }
        @media (max-width: 835px) {
          display: block;
          > div {
            margin: 0 12px 10px;
            width: calc(100% - 24px) !important;
          }
        }
      }
    }
    &__left {
      width: calc(45% - 24px);
      .step__item {
        color: $primary;
        display: flex;
        opacity: 0.4;
        span {
          @include size(20px);
          background: $primary;
          border-radius: 100%;
          color: #ffffff;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          font-size: 14px;
          line-height: 19px;
          margin-right: 16px;
          font-weight: bold;
        }
        p {
          margin: 0;
        }
        &:focus {
          outline: none;
        }
        &.--active {
          opacity: 1;
          p {
            font-weight: 600;
          }
        }
      }

      .border-dashed {
        border-left: 1px dashed #68c0f6;
        height: 14px;
        margin-left: 9px;
        @media (max-width: 500px) {
          height: 10px;
        }
      }
    }
    &__right {
      width: calc(55% - 24px);
      img {
        width: 100%;
        height: auto;
      }
      p {
        margin: 0 20px;
      }
    }
  }
  &__text {
    padding-bottom: 50px;
    .--note {
      background: #def2ff;
      border-radius: 5px;
      padding: 16px 25px;
      margin-bottom: 40px;
      max-width: 730px;
    }
  }
}

.pull-step1 {
  &__inner {
    p {
      margin: 0 12px 25px;
    }
    .button__group {
      margin: 50px 12px 0;
      text-align: center;
      button {
        width: 200px;
      }
    }
  }
}

.pull-step2 {
  .table {
    min-width: 500px;
    .dropdown-toggle span {
      color: #979797;
    }
    .dropdown-toggle span.SelectCheckbox__value-selected {
      color: #16222f;
    }
    .SelectCheckbox--disabled .dropdown-toggle {
      background-color: #ffffff;
    }
    tr {
      th {
        padding: 11px 20px 11px 22px;
      }
      th,
      td {
        &:last-child {
          width: 26%;
          min-width: 120px;
          text-align: left;
        }
        &:nth-child(1),
        &:nth-child(2) {
          width: 37%;
        }
        vertical-align: middle;
      }
      td {
        padding: 7px 15px 7px 22px;
        font-weight: 400;
        &:first-child {
          padding-left: 6;
          div {
            border: 1px solid #e5e7eb;
            padding: 8px 16px;
            border-radius: 8px;
          }
        }
        &:last-child {
          .status {
            svg {
              margin-right: 13px;
            }
            &.unmapped {
              svg path {
                fill: #979797;
              }
            }
            &.invalid {
              svg path {
                fill: $alert01;
              }
            }
            &.mapped {
              svg {
                @include size(18px);
                path {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
  .button-group {
    margin-top: 30px;
  }
}

.pull-step3 {
  .Table__inner {
    > div {
      min-width: 545px;
    }
  }
  .dropdown-toggle span {
    color: #979797;
  }
  .dropdown-toggle span.SelectCheckbox__value-selected {
    color: #16222f;
  }
  .SelectCheckbox--disabled .dropdown-toggle {
    background-color: #ffffff;
  }
  .Table__inner__header {
    display: flex;
    color: #ffffff;
    background: $black;
    border-radius: 8px;
    padding: 0 22px;
    p {
      padding: 11px 0 11px 22px;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      &:nth-child(1),
      &:nth-child(2) {
        width: 37%;
      }
      &:nth-child(1) {
        padding-left: 0;
      }
      &:nth-child(3) {
        width: 26%;
      }
    }
  }
  .CollapseMapping {
    margin: 0 22px;
    &:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }
    &__button {
      @include flex_parent_between();
      padding: 14px 0;

      &:focus {
        outline: none;
      }
      p {
        margin: 0;
        font-size: 12px;
        line-height: 14px;
      }
      .iconArrow svg path {
        fill: $black;
      }
    }
  }
  .CollapseMapping__table {
    .table {
      tr {
        th,
        td {
          &:last-child {
            width: 26%;
            min-width: 120px;
            text-align: left;
          }
          &:nth-child(1),
          &:nth-child(2) {
            width: 37%;
          }
          vertical-align: middle;
        }
        td {
          padding: 7px 15px 7px 22px;
          font-weight: 400;
          &:first-child {
            div {
              border: 1px solid #e5e7eb;
              padding: 8px 16px;
              border-radius: 8px;
            }
          }
          &:last-child {
            .status {
              svg {
                margin-right: 13px;
              }
              &.unmapped {
                svg path {
                  fill: #979797;
                }
              }
              &.invalid {
                svg path {
                  fill: $alert01;
                }
              }
              &.mapped {
                svg {
                  @include size(18px);
                  path {
                    fill: $primary;
                  }
                }
              }
            }
          }
        }
      }
      tbody {
        tr:last-child {
          td {
            padding-bottom: 22px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .Table__inner__header {
      padding: 0 10px;
      p {
        padding: 11px 0 11px 8px;
      }
    }
    .CollapseMapping {
      margin: 0 10px;
    }
    .CollapseMapping__table {
      .table {
        tr {
          td {
            padding: 7px 15px 7px 5px;
          }
        }
      }
    }
  }
}

.action__item {
  color: #212529;
  font-weight: 400 !important;
}

.pull-step3 .CollapseMapping__step3:nth-child(2) {
  display: none;
}

.checkbox__push__hubspot {
  display: flex;
  .checkbox {
    margin-right: 20px;
    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 25px;
    }
  }
}
