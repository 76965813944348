.card {
  @apply w-full max-w-3xl p-4 rounded-lg;
  @apply bg-white text-black;

  @screen lg {
    @apply p-8;
  }
}

.inner {
  @apply mx-auto;
  @apply grid gap-8 grid-cols-1 items-center justify-items-center;
}

.progress {
  @apply mt-4;
  @apply grid gap-4 items-center;

  grid-template-columns: 1fr min-content;
}

.bar {
  @apply w-full h-1 relative rounded-full;
  @apply bg-gray-600;
}

.filled {
  @apply w-full h-1 rounded-full;
  @apply bg-blue-500;
}

.icon {
  @apply text-gray-600;

  &.complete {
    @apply text-blue-500;
  }
}
