.Branches {
  .filter {
    display: flex;
    justify-content: space-between;
    margin: 0 12px 8px;
    &__left {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 120px);
      > div {
        width: calc(50% - 24px);
        max-width: 308px;
        margin: 0 24px 12px 0;
        min-width: 188px;
      }
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  table {
    min-width: 400px;
  }
}

.Add-branch {
  form {
    padding: 5px 12px;
  }
  &__text {
    .text--xlarge {
      margin-bottom: 32px;
    }
  }
  &__inner {
    max-width: 310px;
    .form-group {
      margin-bottom: 16px;
    }
  }
  .checkbox {
    margin-bottom: 16px;
  }
  .programs {
    &__item {
      @include flex_parent_between();
      background: #e5e7eb;
      border-radius: 8px;
      padding: 10px 16px;
      max-width: 310px;
      width: 100%;
      margin-bottom: 16px;
      p {
        margin: 0;
        font-size: 14px;
        line-height: 19px;
      }
      svg path {
        fill: $black;
      }
      > div:focus {
        outline: none;
      }
    }
  }
  .button-group {
    width: 100%;
    max-width: 310px;
    margin-top: 35px;
    button:last-child {
      width: 65%;
      max-width: 200px;
    }
  }
  @media (max-width: 500px) {
    &__text {
      .text--xlarge {
        margin-bottom: 6px;
      }
    }
    &__inner {
      .form-group {
        margin-bottom: 12px;
      }
    }
    .Place {
      margin-bottom: 12px;
    }
  }
  @media (max-width: 360px) {
    .button-group {
      button {
        width: 50% !important;
        max-width: inherit;
      }
    }
  }
}
