.header {
  @apply w-full;
  @apply flex;
  @apply border-b border-gray-200;
  gap: 1rem;

  @media screen and (max-width: 640px) {
    @apply block;
    @apply text-center;
  }

  &.center {
    @apply block;
    @apply text-center;
  }

  &:not(.center) {
    & > div:nth-child(1) {
      @apply flex flex-col content-center items-center;
      @apply shrink-0;
    }
  }
}

.wrapText {
  @apply w-full;
}

.title {
  @apply mb-4;
  @apply font-bold text-2xl;
}

.subTitle {
  @apply font-bold text-lg text-gray-900 mb-4;
}

.smallTitle {
  @apply mb-6;
  @apply font-bold text-sm text-gray-500;
}
