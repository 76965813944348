.overlay {
  @apply absolute inset-0;
  @apply bg-gray-900 opacity-50;
}

.content {
  @apply centered-dialog p-8 rounded-lg;
  @apply bg-white shadow-lg;
}

.title {
  @apply pb-4 mb-4;
  @apply font-bold text-2xl;
  @apply border-b border-gray-200;
}

.description {
  @apply text-center;

  &.hasTitle {
    @apply text-left;
  }
}

.buttonArea {
  @apply w-full pt-8;
  @apply grid gap-4 grid-cols-2;
}

.button {
  @apply w-full py-3 px-6 rounded-lg;
  @apply font-bold text-sm;
  @apply bg-white text-blue-500;
  @apply transition;
  @apply cursor-pointer select-none;

  &:focus {
    @apply focus-ring;
  }

  &:hover {
    @apply bg-gray-100;
  }

  &:active {
    @apply bg-gray-100 shadow-inner;
  }

  &.accept {
    @apply bg-blue-500 text-white;

    &:hover {
      @apply bg-blue-400;
    }

    &:active {
      @apply bg-blue-600 shadow-inner;
    }
  }

  &.danger {
    @apply bg-red-500 text-white;

    &:focus {
      @apply focus-ring-error;
    }

    &:hover {
      @apply bg-red-400;
    }

    &:active {
      @apply bg-red-600 shadow-inner;
    }
  }
}
