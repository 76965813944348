.file {
  @apply w-full p-2 rounded-lg;
  @apply grid gap-4 items-center;
  @apply bg-gray-200 text-gray-900;
  @apply text-center text-sm;

  grid-template-columns: 1.5rem 1fr 1.5rem;
}

.filename {
  @apply col-start-2;
}

.link {
  @apply text-blue-600 underline;
}

.fileDelete {
  @apply w-full;
  @apply text-center text-lg text-gray-500;
  @apply transition cursor-pointer;

  &:hover {
    @apply text-red-600;
  }

  &:focus {
    @apply focus-ring rounded;
  }
}
