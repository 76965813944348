.Responses {
  .filter {
    display: flex;
    justify-content: space-between;
    margin: 0 12px 24px;
    &__left {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 120px);
      > div {
        width: 100%;
        max-width: 308px;
        margin: 0 24px 12px 0;
        min-width: 188px;
      }
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  &__link {
    display: block;
    color: var(--bs-table-striped-color);
    &:hover {
      color: #0199f2;
    }
  }
  table {
    min-width: 900px;
    tr {
      th,
      td {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 12%;
        }
        &:nth-child(4) {
          width: 14%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 20%;
        }
        &:nth-child(7) {
          width: 20%;
        }
        &:last-child {
          text-align: left;
        }
      }
    }
    @media (max-width: 600px) {
      min-width: 720px;
    }
  }

  .responses__link {
    padding: 4px 2px;
    cursor: pointer;
    position: relative;
    svg {
      width: 20px !important;
    }
    path {
      fill: $primary01 !important;
    }
    &:hover {
      path {
        fill: $primary !important;
      }
      .responses__tooltip {
        display: block;
      }
    }
  }

  .responses__tooltip {
    position: absolute;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    padding: 6px 8px;
    border-radius: 6px;
    color: #fff;
    width: 120px;
    word-break: break-all;
    display: none;
    &:before {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000;
    }
  }

  .responses__status {
    text-transform: capitalize;
    &--pending {
      color: $alert01;
    }
    &--completed {
      color: $success01;
    }
    &--in-progress {
      color: $warning01;
    }
  }

  .responses__student__link {
    display: block;
    color: var(--bs-table-striped-color);
    &:hover {
      color: $primary;
    }
  }
}
