.form-group__typeahead {
  .invalid-feedback {
    display: block;
  }
  .form-control {
    font-size: 12px;
    &:disabled {
      background-color: #ffffff;
    }
  }
  .dropdown-item {
    padding: 5px 16px;
    line-height: 16px;
    font-size: 12px;
    white-space: normal;
  }
}
