.ForgotPassword {
  .form__inner {
    padding: 24px 25px;
    button {
      width: 100%;
      margin-top: 8px;
    }
  }
  .form-message {
    padding: 30px 0;
  }
  @media (max-width: 500px) {
    .form__inner {
      padding: 16px 15px 14px;
    }
  }
}
