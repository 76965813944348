.Profile {
  &__inner {
    display: flex;
    justify-content: space-between;
    .info,
    button {
      margin: 8px 12px;
    }
    .info {
      width: calc(100% - 186px);
      max-width: 570px;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        p {
          margin: 0;
          &:first-child {
            width: 43%;
            max-width: 170px;
          }
          &:last-child {
            width: 57%;
          }
        }
        img {
          @include size(40px);
          border-radius: 100%;
        }
      }
    }
    button {
      width: 138px;
    }
    @media (max-width: 765px) {
      flex-direction: column;
      .info {
        width: calc(100% - 24px);
      }
    }
    @media (max-width: 600px) {
      .info {
        width: calc(100% - 24px);
        > div {
          margin-bottom: 20px;
        }
        img {
          width: 32px;
        }
      }
    }
  }
}

.Profile {
  .EditProfile {
    &__text {
      margin: 0 12px;
    }
    .add-avatar {
      margin: 0 12px 16px;
      > div {
        display: flex;
        align-items: center;

        .add_image {
          position: relative;
          > svg {
            width: 20px;
            height: 20px;
            transform: rotate(-90deg);
            path {
              fill: $black;
            }
          }
          > div {
            cursor: pointer;
            height: 30px;
            width: 30px;
            position: absolute;
            top: -2px;
            overflow: hidden;
            opacity: 0;
          }
        }
      }
      .avatar {
        @include flex_parent_centered;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 16px;
      }
    }
  }
}
