.Attendance {
  max-width: 1000px !important;
  width: 100%;
  margin: 0 auto;
  padding: 40px 16px !important;

  h5 {
    font-size: 28px !important;
    margin: 0 0 24px !important;
  }

  label {
    font-size: 16px !important;
  }

  .text {
    font-size: 16px !important;
    margin: 0 0 16px !important;
    line-height: 1.5 !important;
  }

  &__input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    margin: 0 0 16px;

    input {
      background-color: #dfdfdf !important;
    }

    & > div {
      flex: 1;
    }
  }

  &__radio {
    margin: 0 0 16px;
    legend {
      font-size: 16px !important;
      line-height: 1 !important;
      margin: 24px 0 0 !important;
    }
    label {
      display: flex !important;
    }
  }

  &__button-group {
    button {
      max-width: 160px;
      margin: 0 auto;
    }
  }
}
