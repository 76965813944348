.dashboard-student {
  height: 100%;
  max-width: 100% !important;
  padding: 40px 16px 0 !important;
  height: 100% !important;
}

.board-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  padding-bottom: 40px;
  gap: 16px;
}

.board-column {
  width: 280px;
  margin: 0;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    max-height: 100%;
    background: #c1d4e8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 12px 20px 8px;
    margin: 0 0 4px;
    h6 {
      line-height: 20px;
      margin: 0;
      text-transform: capitalize;
    }
  }

  ul {
    padding: 0 8px !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    li {
      padding: 12px 12px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 1px 1px #091e4240;
      margin: 0 0 8px;
      h6 {
        font-size: 14px;
        display: block;
        cursor: pointer;
        margin: 0;
        color: var(--bs-table-striped-color);
        &:hover {
          color: #0199f2;
        }
      }
      p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      button {
        padding: 4px 16px;
        background: #0199f2;
        color: white;
        font-size: 12px;
        float: right;
        margin: 4px 0 0;
        &:hover {
          background: #3bacf4;
        }
      }
    }
  }
}
