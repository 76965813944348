.message {
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 560px;
  background: transparent;
  padding: 0 20px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 4px;
  z-index: 9;
  .toast-header {
    border-radius: 8px;
    border: none;
    background: #9fefc9;
    padding: 8px 15px;
    img {
      @include size(20px);
      margin-right: 15px;
    }
    p {
      margin: 0;
      color: $black;
    }
  }
}

.message-success {
  .toast-header {
    background: $success03;
    button span {
      &:first-child {
        color: $success01;
        font-size: 28px;
      }
    }
  }
}

.message-error {
  .toast-header {
    background: $alert03;
    button span {
      &:first-child {
        color: $alert01;
        font-size: 28px;
      }
    }
  }
}
