.textArea {
  @apply w-full mb-2;
}

.label {
  @apply block w-full;
  @apply font-bold text-sm leading-normal;
  @apply text-gray-900;
}

.error {
  @apply block w-full;
  @apply text-sm text-right leading-none;
  @apply text-red-400 dark:text-red-500;
}

.input {
  @apply w-full py-2 px-4 mb-6;
  @apply bg-white;
  @apply text-base text-gray-800;
  @apply placeholder-gray-400;
  @apply border border-gray-300 rounded-lg;

  &:last-child {
    @apply mb-0;
  }

  &:focus {
    @apply focus-ring;
  }

  &.hasError {
    @apply border-red-400;

    &:focus {
      @apply focus-ring-error;
    }
  }
}

:global {
  ::-webkit-datetime-edit {
    @apply text-gray-900;
  }

  ::-webkit-datetime-edit-text {
    @apply text-gray-500;
  }

  ::-webkit-calendar-picker-indicator {
    @apply text-gray-500;
  }
}
