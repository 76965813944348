.bg-form {
  background: url(../../assets/images/bg.svg) $black no-repeat center/cover;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.academy-form {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  form {
    background: #ffffff;
    box-shadow: 0px 17px 85px rgba(26, 26, 26, 0.06);
    border-radius: 8px;
    max-width: 512px;
    width: 100%;
    margin: 20px auto;
    padding: 40px 40px 24px;
    @media (max-width: 600px) {
      padding: 30px 15px 14px;
    }
  }
}
.logo-form {
  padding: 22px 40px;
  min-height: 80px;
  @media (max-width: 500px) {
    padding: 22px 20px;
  }
  @media (max-width: 315px) {
    img {
      height: 26px;
    }
  }
}
