.Cohorts {
  .filter {
    display: flex;
    justify-content: space-between;
    margin: 0 12px 24px;
    &__left {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 120px);
      > div {
        width: 100%;
        max-width: 308px;
        margin: 0 24px 12px 0;
        min-width: 188px;
      }
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  table {
    min-width: 900px;
    tr {
      th,
      td {
        &:nth-child(1) {
          width: 24%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 14%;
        }
        &:nth-child(5) {
          width: 20%;
        }
      }
    }
    @media (max-width: 600px) {
      min-width: 720px;
    }
  }
}

.Add-Cohort {
  form {
    padding: 5px 12px;
  }
  &__text {
    .text--xlarge {
      margin-bottom: 32px;
    }
  }
  &__inner {
    max-width: 310px;
    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .button-group {
    width: 100%;
    max-width: 310px;
    margin-top: 35px;
    button:last-child {
      width: 65%;
      max-width: 200px;
    }
  }
  @media (max-width: 500px) {
    &__text {
      .text--xlarge {
        margin-bottom: 6px;
      }
    }
    &__inner {
      > div:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  @media (max-width: 360px) {
    .button-group {
      button {
        width: 50% !important;
        max-width: inherit;
      }
    }
  }
}
