// ====================================
// Mixins
// ====================================
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;
  @if length($value) > 1 {
    $height: nth($value, 2);
  }
  @if is-size($height) {
    height: $height;
  }
  @if is-size($width) {
    width: $width;
  }
}

@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;
  @if ($direction == up) or
    ($direction == down) or
    ($direction == right) or
    ($direction == left)
  {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);
    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;
    @if $direction == up-right {
      border-left: $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;
    @if $direction == down-right {
      border-left: $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color
      $background-color
      $background-color
      $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color
      $foreground-color
      $background-color
      $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}

@mixin scrollbar(
  $width,
  $color_track,
  $radius_track,
  $color_thumb,
  $radius_thumb
) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $color_track;
    border-radius: $radius_track;
  }
  &::-webkit-scrollbar-thumb {
    background: $color_thumb;
    border-radius: $radius_thumb;
  }
}

@mixin scrollbarIE($scroll-color, $track-color) {
  scrollbar-base-color: $scroll-color;
  scrollbar-3dlight-color: $scroll-color;
  scrollbar-highlight-color: $scroll-color;
  scrollbar-shadow-color: $scroll-color;
  scrollbar-dark-shadow-color: $scroll-color;
  scrollbar-track-color: $track-color;
  scrollbar-arrow-color: $track-color;
}

@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }
  position: $position;
  top: nth($coordinates, 1);
  right: nth($coordinates, 2);
  bottom: nth($coordinates, 3);
  left: nth($coordinates, 4);
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin absolute_centered($dir: '') {
  @if $dir == 'x' {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  } @else if $dir == 'y' {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  } @else {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@mixin fill_parent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin fill_window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin flex_parent_centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex_parent_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex_parent_around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
