.trigger {
  @apply h-6 w-6;
  @apply text-gray-900 leading-none;
  @apply rounded;
  @apply bg-blue-400;

  &:focus {
    // TODO: add focus styles once we get rid of bootstrap
    @apply outline-none;
    // @apply focus-ring;
  }
  &:hover {
    @apply bg-blue-500;
  }
}

.content {
  @apply p-1;
  @apply grid grid-cols-1;
  @apply bg-white rounded drop-border;
  @apply z-50;

  &[data-state="open"] {
    animation: table-actions-open 100ms ease-out;
  }

  &[data-state="closed"] {
    animation: table-actions-close 100ms ease-out;
  }
}

.arrow {
  @apply text-white fill-current;
}

.action {
  @apply w-full px-2 py-1 rounded;
  @apply grid gap-2 items-center justify-center text-left;
  @apply text-sm text-blue-600;
  @apply cursor-pointer select-none;

  grid-template-columns: theme("spacing.4") 1fr;

  &:hover,
  &:focus {
    @apply outline-none bg-gray-100;
  }
}

.label {
  @apply pr-1;
}
.tooltipContent {
  @apply bg-black rounded p-2 text-xs;
  @apply text-white text-xs leading-3;
}

@keyframes table-actions-open {
  from {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes table-actions-close {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
}
