.Date-picker {
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }
  &__input {
    display: flex;
    align-items: center;
    line-height: inherit;
    color: #16222f;
    padding: 6px 24px 6px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 14px;
    line-height: 19px;
    height: 40px;
    background: #ffffff;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s linear;
    background: url(../../assets/images/DatePicker.svg) #ffffff no-repeat calc(100% - 16px)
      center/16px;
    &:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }
  @media (max-width: 500px) {
    &__label {
      font-size: 12px;
      line-height: 16px;
    }
    &__input {
      height: 36px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.daterangepicker {
  box-shadow: 0px 4px 35px rgba(78, 84, 91, 0.15);
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  .drp-calendar {
    padding: 16px 16px 4px !important;
  }
  .calendar-table {
    padding: 0 !important;
    .month {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 19px;
    }
    .prev,
    .next {
      background: #ffffff !important;
      position: relative;
      padding-bottom: 8px;
      &::after {
        content: '';
        display: block;
        border-radius: 8px;
        border: 1.5px solid #e5e7eb;
        width: 30px;
        height: 24px;
        position: absolute;
        top: 0;
      }
    }
    .prev span {
      margin-left: 4px;
    }
    .next span {
      margin-right: 4px;
    }
    thead tr:last-child th,
    tbody tr td {
      @include size(32px);
      &:nth-child(odd) {
        background: #f6f6f9;
      }
    }
    tbody tr {
      th {
        font-weight: 600;
      }
      td {
        border-radius: 0;
        @include size(32px);
        &:hover {
          background: #def2ff;
          border-radius: 5px !important;
        }
        &.active {
          background: $primary !important;
          border-radius: 5px;
        }
        &.in-range {
          background: #def2ff;
        }
      }
    }
    thead tr:last-child td {
      border-radius: 5px 5px 0 0;
    }

    tbody tr:last-child td {
      border-radius: 0 0 5px 5px;
    }
  }
  .drp-buttons {
    border: none;
    text-align: center;
    .drp-selected,
    .cancelBtn {
      display: none;
    }
    .applyBtn {
      width: calc(100% - 16px);
      margin: 0 8px;
      font-size: 14px;
      padding: 10px 8px;
      line-height: 18px;
      border-radius: 8px;
      max-width: 225px;
    }
  }
  &.single {
    width: 257px;
    .drp-calendar.single {
      padding: 16px 16px 4px;
    }
    .calendar-table {
      .monthselect {
        width: 40%;
        margin-bottom: 8px;
      }
    }
  }
}

.Date-picker__input__disabled {
  background-color: #e9ecef;
  pointer-events: none;
}

.DateRangePicker {
  position: relative;
  input {
    width: 100% !important;
  }
  &__reset {
    padding: 0px;
    height: 1.2rem;
    width: 1.2rem;
    line-height: 1.225rem;
    text-align: center;
    border-radius: 100%;
    border: 0;
    margin: 0;
    position: absolute;
    top: 11px;
    right: 42px;
  }
}
