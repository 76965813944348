.SelectCheckbox {
  .dropdown-toggle {
    display: block;
    color: $black;
    padding: 13.5px 30px 13.5px 16px;
    font-size: 14px;
    line-height: 19px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s linear;
    &::after {
      @include size(20px);
      @include position(absolute, calc(50% - 10px) 10px null null);
      border: none;
      background: url(../../assets/icons/down.svg) no-repeat center/20px;
    }
  }
  .dropdown-menu {
    border-radius: 8px;
    box-shadow: 0px 12px 25px rgba(99, 99, 99, 0.15);
    padding: 10px 0;
    width: 100%;
    min-width: auto;
    border: 0;
    max-height: 250px;
    overflow: auto;
    .dropdown-item {
      padding: 6px 16px;
      font-size: 14px;
      &:active {
        color: $black;
        background-color: #f6f6f9;
      }
    }
  }
  &__value-selected:not(:first-child) {
    margin-left: 4px;
  }
  &--disabled {
    .dropdown-toggle {
      pointer-events: none;
      cursor: not-allowed;
      background-color: #e9ecef;
    }
  }
  &--loading {
    .dropdown-toggle {
      pointer-events: none;
      cursor: not-allowed;
      &::after {
        display: none;
      }
    }
  }
  &__loading {
    @include position(absolute, calc(50% - 12px) 10px null null);
    @include size(24px);
  }
}
.SelectCheckbox--dark .dropdown-toggle {
  background: $gray07;
  &::after {
    background: url(../../assets/icons/down2.svg) no-repeat center/20px;
  }
}

.SelectCheckbox--show > .dropdown-toggle {
  border-color: $primary !important;
  background-color: $gray09;
  &::after {
    transform: rotate(180deg);
  }
}

.SelectCheckbox.show > .dropdown-toggle {
  border-color: $primary !important;
  background-color: $gray09;
  &::after {
    transform: rotate(180deg);
  }
}

.SelectCheckbox--small {
  .dropdown-toggle {
    padding: 9.5px 30px 9.5px 16px;
    font-size: 12px;
  }
  .SelectCheckbox__item {
    padding: 2px 16px;
    &__label {
      font-size: 12px;
    }
  }
}

.SelectCheckbox__item {
  padding: 5px 16px;
  &:hover,
  &.--selected {
    background: #f6f6f9;
  }
  &__label {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    margin: 0;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 9;
    height: 100%;
    &:checked ~ .SelectCheckbox__item__checkmark {
      background-color: $primary;
      border: none;
      &::after {
        display: block;
      }
    }
  }
  &__checkmark {
    position: absolute;
    left: 0;
    top: 4px;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #cbcdd1;
    border-radius: 4px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      background: url(../../assets/icons/tick.svg) no-repeat center/8px;
      width: 8px;
      height: 8px;
    }
  }
}

.select {
  margin-bottom: 16px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .dropdown-toggle {
    padding: 9.5px 32px 9.5px 16px;
    border: 1px solid #e5e7eb;
    span {
      color: #68717b;
    }
    .SelectCheckbox__value-selected {
      color: $black;
    }
    &::after {
      right: 16px;
    }
  }
  .SelectCheckbox__item {
    &__label {
      font-size: 14px;
    }
  }
  @media (max-width: 500px) {
    margin-bottom: 12px;
    p,
    .dropdown-toggle {
      font-size: 12px;
      line-height: 16px;
    }
    .dropdown-toggle {
      height: 36px;
    }
    .SelectCheckbox__item {
      padding: 2px 16px;
      &__label {
        font-size: 12px;
      }
    }
  }
}

.Select--error {
  .dropdown-toggle {
    border-color: #f7553f !important;
  }
}

// single select
.SingleSelectCheckbox {
  .SelectCheckbox__item {
    &__label {
      padding-left: 0;
    }
    &__checkmark {
      opacity: 0;
    }
  }
}
