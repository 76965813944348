html {
  font-size: $font-size; //base value for rem
}

body {
  font-family: $main-font;
  font-size: $font-size;
  line-height: 1.5rem; // 24px;
  font-weight: 400;
  color: $black;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Karmina Sans';
  font-weight: bold;
}

h1 {
  font-size: 5rem; // 80px
  letter-spacing: -0.16rem; // ~ -2.6px;
  line-height: 5rem; // 80px;
  @media (max-width: 1124px) {
    font-size: 3.5rem;
    line-height: 3rem;
  }
  @media (max-width: 500px) {
    font-size: 3rem;
    line-height: 2.7rem;
  }
}

h2 {
  font-size: 4rem; // 64px
  letter-spacing: -0.16rem; // ~ -2.6px;
  line-height: 4rem; // 64px
  @media (max-width: 1124px) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media (max-width: 500px) {
    font-size: 2rem;
    line-height: 2rem;
  }
}

h3 {
  font-size: 3.5rem; // 56px
  line-height: 3.8rem; // 60px;
  @media (max-width: 1124px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  @media (max-width: 500px) {
    font-size: 2rem;
    line-height: 2rem;
  }
}

h4 {
  font-size: 2rem; // ~ 32px
  line-height: 2.15rem; // 34px;
  @media (max-width: 1124px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
}

//text
.text {
  font-size: 1rem;
  @media (max-width: 1123px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  @media (max-width: 500px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
  &--xxxlarge {
    font-size: 3rem; // 48px;
    line-height: 3rem; // 48px;
    @media (max-width: 500px) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  &--xxlarge {
    font-size: 1.5rem; // 24px;
    line-height: 2.25rem; // 36px;
    @media (max-width: 1124px) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    @media (max-width: 500px) {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }

  &--xlarge {
    font-size: 1.3rem; // ~ 21px
    line-height: 1.8rem; // ~28px
    @media (max-width: 1124px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    @media (max-width: 500px) {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  }

  &--large {
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 1050px) {
      font-size: 1rem;
      line-height: 20px;
    }
    @media (max-width: 500px) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  &--small {
    font-size: 14px;
    line-height: 19px;
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 1rem;
    }
  }

  &--xsmall {
    font-size: 0.75rem; // 12px
    line-height: 1rem; // 16px
    @media (max-width: 500px) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }
}

.bold {
  font-weight: 600;
}
