.Dropdown {
  .dropdown-toggle {
    @include flex_parent_between();
    color: $black;
    padding: 12px 14px 12px 16px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    height: 48px;
    &::after {
      width: 20px;
      height: 20px;
      border: none;
    }
  }
  .dropdown-menu {
    border-radius: 8px;
    box-shadow: 0px 12px 25px rgba(99, 99, 99, 0.15);
    padding: 10px 0;
    width: 100%;
    min-width: 100px;
    border: 0;
    .dropdown-item {
      padding: 6px 16px;
      font-size: 14px;
      &:active {
        color: $black;
        background-color: #f6f6f9;
      }
    }
  }
}
.Dropdown.show > .dropdown-toggle {
  border-color: $primary;
  &::after {
    transform: rotate(180deg);
  }
}
.Dropdown--light {
  .dropdown-toggle {
    border: 1px solid #e5e7eb;
    background: $white;
    &::after {
      background: url(../../assets/icons/down.svg) no-repeat center/20px;
    }
  }
}

.Dropdown--dark {
  .dropdown-toggle {
    border: 1px solid #f0f1f2;
    background: #f0f1f2;
    &::after {
      background: url(../../assets/icons/down2.svg) no-repeat center/20px;
    }
  }
}

.Dropdown--find {
  .dropdown-toggle {
    &::after {
      background: url(../../assets/icons/find.svg) no-repeat center/20px;
    }
  }
}

.Dropdown-small {
  .dropdown-toggle {
    padding: 3px 8px 3px 12px;
    font-size: 12px;
    line-height: 16px;
    height: auto;
    &::after {
      width: 16px;
      height: 16px;
      background-size: 16px;
    }
  }
  .dropdown-menu {
    padding: 6px 0;
    .dropdown-item {
      font-size: 12px;
      padding: 4px 16px;

      svg {
        display: inline;
        height: 16px;
      }
    }
  }
}
