.layout {
  @apply w-full min-h-screen-mobile p-4;
  @apply grid gap-8 grid-cols-1 items-center justify-items-center;
  @apply bg-gray-800;

  background: url(../../../../assets/images/survey-background.svg) no-repeat
    center/cover;
  grid-template-rows: min-content 1fr;
}

.header {
  @apply w-full;
}

.content {
  @apply w-full max-w-3xl;
}

.logo {
  @apply w-36;

  :global {
    .logo-color-white {
      @apply text-white fill-current;
    }

    .logo-color-blue {
      @apply text-blue-500 fill-current;
    }
  }
}
