// input
.form-group {
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  &--custom {
    .invalid-feedback {
      display: block !important;
    }
  }
  .form-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .form-control {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 16px;
    color: $black;
    &::placeholder {
      color: $gray03;
    }
    &:focus {
      border: 1px solid $primary;
      box-shadow: none;
    }
  }
  input.form-control {
    height: 40px;
  }
  textarea {
    height: 140px;
  }
  .invalid-feedback {
    color: $alert01;
    font-size: 12px;
    line-height: 18px;
  }

  .hide-password {
    position: absolute;
    right: 10px;
    top: 33px;
    cursor: pointer;
    img {
      @include size(20px);
    }
  }
  @media (max-width: 500px) {
    .form-label,
    .form-control {
      font-size: 12px;
      line-height: 16px;
    }
    input.form-control {
      height: 36px;
    }
    textarea {
      height: 100px;
    }
  }
}

.was-validated .form-control:invalid {
  border-color: $alert01;
  background-image: none;
}
.form-control.is-invalid {
  border-color: $alert01 !important;
  background-image: none !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
  }
}

.form-control.inputSearch {
  color: $black;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 34px 5px 16px;
  background: url(../../assets/icons/find.svg) #ffffff no-repeat
    calc(100% - 10px) center/20px;
  &::placeholder {
    color: $black;
  }
}
// Check

.checkbox {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  .form-group {
    margin: 0;
    position: initial;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      left: 0;
      z-index: 9;
      height: 100%;
      &:checked ~ .checkmark:after {
        display: block;
      }
      &:checked ~ .checkmark--radio {
        border: 1px solid $primary;
        background: #ffffff;
      }
      &:checked ~ .checkmark--checkbox {
        background: $primary;
        border: none;
      }
    }
    .checkmark {
      position: absolute;
      left: 0;
      top: 4px;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border: 1px solid #cbcdd1;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    .checkmark--checkbox {
      border-radius: 4px;
      &::after {
        top: 4px;
        left: 4px;
        background: url(../../assets/icons/tick.svg) no-repeat center/8px;
        width: 8px;
        height: 8px;
      }
    }
    .checkmark--radio {
      border-radius: 100%;
      &::after {
        top: 3px;
        left: 3px;
        width: 8px;
        height: 8px;
        background: $primary;
        border-radius: 100%;
      }
    }
    .checkmark--checked {
      border: 1px solid $primary;
      background: #ffffff;
      &::after {
        display: block;
      }
    }
  }
}

.Place {
  margin-bottom: 16px;
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }
  &__error {
    color: #f7553f;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    margin-top: 0.25rem;
  }
  input {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 14px;
    line-height: 19px;
    height: 40px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    &:focus,
    &:active {
      border: 1px solid #0199f2;
      box-shadow: none;
      outline: 0;
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
  }
  @media (max-width: 500px) {
    &__label {
      font-size: 12px;
      line-height: 16px;
    }
    input {
      height: 36px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
