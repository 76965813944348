.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  .react-datepicker {
    box-shadow: 0px 4px 35px rgba(78, 84, 91, 0.15);
    border-radius: 10px;
    border: none;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
    &__header {
      background: #ffffff;
      border: none;
      > div {
        align-items: center;
      }
    }
    &__triangle {
      display: none;
    }
    &__month-container {
      width: 257px;
    }
    &__day-name {
      margin: 0;
      width: 32px;
      height: 37px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding-top: 10px;
      font-weight: 600;
      &:nth-child(odd) {
        background: #f6f6f9;
      }
    }
    &__month {
      margin: 0 0 16px 0;
      .react-datepicker__week:last-child {
        .react-datepicker__day {
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }
    &__day {
      margin: 0;
      width: 32px;
      height: 32px;
      font-size: 12px;
      padding: 2.5px;
      &:nth-child(odd) {
        background: #f6f6f9;
      }
      &--selected {
        background: #0199f2 !important;
        border-radius: 5px;
      }
      &--keyboard-selected {
        border-radius: inherit;
        background-color: inherit;
        color: inherit;
      }
      &:hover,
      &:focus {
        background: #def2ff;
        border-radius: 5px !important;
        outline: none;
      }
      &--outside-month {
        color: #999;
      }
      &--disabled {
        pointer-events: none;
      }
    }
    .btn-month {
      @include flex_parent_centered();
      border: 1.5px solid #e5e7eb;
      background: #ffffff;
      border-radius: 6px;
      width: 35px;
      height: 25px;
      svg path {
        fill: $black;
      }
    }
    .date-picker-select {
      font-size: 12px;
      line-height: 16px;
      height: 23px;
      &.select-year {
        margin: 0 4px 0 8px;
      }
      &.select-month {
        margin: 0 8px 0 4px;
      }
    }
  }
}
