.Banner {
  color: $white;
  background: url(../../assets/images/contact-us-background.png) $black
    no-repeat center/cover;
  margin-top: $minHeightHeader;
  padding: 175px 0 500px;
  @media (min-width: 1450px) {
    background-position: top;
  }
  @media (max-width: 990px) {
    padding: 22% 0;
  }
  &__inner {
    &__left {
      max-width: 450px;
      h3 {
        margin-bottom: 20px;
      }
    }
  }
}

.Form {
  margin-top: -340px;
  @media (max-width: 990px) {
    margin-top: 20px;
  }
  &__inner {
    box-shadow: 0px 0px 17px 85px rgba(26, 26, 26, 0.06);
    display: flex;
    position: relative;
    border-radius: 8px;
    .title {
      font-family: "Karmina Sans";
      text-transform: capitalize;
      margin-bottom: 40px;
    }

    &__left {
      width: 65%;
      padding: 30px 30px;
      position: relative;
      background: $white;
      border-radius: 8px 0 0 8px;
      .input-group {
        > div {
          width: calc(50% - 20px);
        }
      }
      .form-group {
        margin: 0 20px 30px 0;
        @media (max-width: 500px) {
          margin: 0 20px 15px 0;
        }
      }
      button {
        width: 68px;
        height: 68px;
        border-radius: 100%;
        position: absolute;
        right: 20px;
        bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        img {
          position: relative;
          top: 1px;
          left: -1px;
        }
      }
    }
    &__right {
      color: $white;
      width: 35%;
      padding: 30px 50px;
      background: $complementary02;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 0 8px 8px 0;
      img {
        opacity: 0.5;
      }
    }
    .infomation__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 35px;
      img {
        margin-right: 15px;
      }
    }
    .social {
      a {
        margin-right: 25px;
        transition: 0.2s linear;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
      }
      img {
        @include size(40px);
      }
    }
    @media (max-width: 1100px) {
      &__right {
        padding: 30px 40px;
      }
    }
    @media (max-width: 990px) {
      flex-direction: column;
      > div {
        width: 100%;
      }
      &__left {
        border-radius: 8px 8px 0 0;
      }
      &__right {
        border-radius: 0px 0 8px 8px;
      }
    }
    @media (max-width: 600px) {
      .title {
        margin-bottom: 25px;
      }
      &__left,
      &__right {
        padding: 20px 15px;
      }
      .input-group {
        flex-direction: column;
        > div {
          width: calc(100% - 20px);
        }
      }
      .infomation__item {
        margin-bottom: 5px;
      }
      .social {
        img {
          @include size(30px);
        }
      }
    }
  }
}
