.footer {
  &__inner {
    padding: 40px;
    @include flex_parent_between;
    &__left {
      display: flex;
      img {
        display: inline-block;
        margin-right: 10px;
      }
      & > p {
        line-height: 30px;
      }
    }
    p {
      margin: 0 50px 0 0;
    }
    a {
      color: $black;
      &:not(:last-child) {
        margin: 0 20px 0 0;
      }
      &:hover {
        color: $primary;
      }
    }
    @media (max-width: 1175px) {
      align-items: end;
      &__left {
        flex-direction: column;
      }
    }
    @media (max-width: 730px) {
      flex-direction: column;
      max-width: 340px;
      margin: 0 auto;
      padding: 30px 20px;
      p {
        margin: 0;
      }
      &__left {
        margin-bottom: 20px;
      }
      &__right {
        width: 100%;
      }
    }
  }
  &__link_one_line {
    white-space: nowrap;
  }
}
