.get-start {
  background: url(../../assets/images/header-image.png) no-repeat 100% 100% / auto 100%;
  padding: 17% 0;
  position: relative;
  margin-bottom: 132px;
  margin-top: $minHeightHeader;
  @media (min-width: 1440px) {
    padding: 242px 0;
  }
  &__inner {
    &__left {
      width: 100%;
      max-width: 500px;
      h3 {
        margin-bottom: 44px;
      }
      p {
        margin-bottom: 32px;
      }
    }
    button {
      text-transform: uppercase;
    }
    .triangle {
      position: absolute;
      bottom: -132px;
    }
  }
  .get-start__image {
    text-align: center;
  }
  .header-image {
    display: none;
    width: 300px;
    margin: 0 0 0 auto;
  }
  @media (max-width: 1124px) {
    margin-bottom: 80px;
    &__inner {
      &__left {
        max-width: 351px;
      }
    }
    .triangle {
      bottom: -80px;
    }
  }
  @media (max-width: 890px) {
    background-size: 380px;
    padding: 65px 0;
  }
  @media (max-width: 780px) {
    background: none;
    margin-bottom: 0;
    padding: 50px 0;
    &__inner__left {
      text-align: center;
      margin: 0 auto;
      h3 {
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 20px;
      }
      button {
        margin-bottom: 20px;
      }
    }
    .triangle {
      bottom: 50px;
    }
    .header-image {
      display: block;
    }
  }
  @media (max-width: 450px) {
    .triangle {
      display: none;
    }
  }
}

.About-us {
  position: relative;
  &__inner {
    position: relative;
    padding: 6% 0 0;
    background: url(../../assets/images/illustration.png) no-repeat 0 70%/50%;
    &__left {
      display: none;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__right {
      width: 50%;
      max-width: 500px;
      margin: 0 0 0 auto;
      .title {
        margin-bottom: 30px;
      }
      .link--normal {
        margin-top: 60px;
        display: flex;
      }
    }
  }
  .triangle__wrap {
    text-align: right;
    margin-top: -58px;
  }
  @media (max-width: 767px) {
    &__inner {
      background: none;
      padding: 25px 0 50px;
      &__left {
        display: block;
      }
      &__right {
        width: 100%;
        margin: 0 auto;
      }
    }
    .triangle__wrap {
      margin-top: 0;
    }
    .triangle {
      display: none;
      margin-top: 0;
    }
  }
}

.How-It-works {
  position: relative;
  &__inner {
    padding: 8% 0;
    .title {
      margin-bottom: 6.5%;
      h4 {
        margin-bottom: 25px;
      }
    }
  }
  &__group {
    display: flex;
  }
  &__item {
    background: $white;
    padding: 32px 54px 70px 32px;
    border-radius: 8px;
    margin: 10px 15px;
    width: calc(33.33% - 30px);
    img {
      width: 85px;
      height: 70px;
      margin-bottom: 18px;
    }
  }
  @media (max-width: 1020px) {
    &__item {
      padding: 30px 20px;
    }
  }
  @media (max-width: 767px) {
    &__group {
      flex-direction: column;
    }
    &__item {
      width: calc(100% - 30px);
      img {
        width: 70px;
        height: 58px;
      }
    }
  }
  @media (max-width: 400px) {
    &__inner {
      padding: 25px 0;
      .title h4 {
        margin-bottom: 15px;
      }
    }
    &__item {
      padding: 20px 20px 10px;
      img {
        width: 55px;
        height: 45px;
        margin-bottom: 15px;
      }
    }
  }
}

.Agencies {
  position: relative;
  border-bottom: 1px solid $primary04;
  background: url(../../assets/images/triangles5.png) no-repeat 100% 50%/7%;
  &__inner {
    padding: 4.5% 0 4%;
    position: relative;
    .title {
      margin-bottom: 6.5%;
      h4 {
        margin-bottom: 25px;
      }
    }
    .visual {
      display: none;
      width: 200px;
    }
    .reasons {
      display: flex;
      text-align: center;
      &__item {
        > div {
          text-align: left;
          padding: 20px 10px;
          margin: 16px;
          .text--xlarge {
            font-family: 'Karmina Sans';
          }
        }
        img {
          width: 326px;
          height: 334px;
        }
        @media (max-width: 895px) {
          img {
            width: 250px;
            height: 256px;
          }
        }
      }
      span {
        width: 36px;
        height: 10px;
        display: inline-flex;
        border-radius: 14px;
        margin-left: 10px;
      }
      .enrollment span {
        background: $complementary03;
      }
      .placement span {
        background: $complementary01;
      }
      .completion span {
        background: $primary;
      }
    }
    .triangle {
      width: 160px;
      height: 160px;
      position: absolute;
      bottom: 14%;
    }
  }
  @media (max-width: 720px) {
    background: none;
    &__inner {
      .reasons__item {
        > div {
          margin: 0;
        }
        img {
          width: 145px;
          height: 150px;
        }
      }
      .triangle {
        width: 100px;
        height: 100px;
      }
    }
  }
  @media (max-width: 430px) {
    background: none;
    &__inner {
      padding: 25px 0 10px;
      .reasons {
        flex-direction: column;
      }
      .visual {
        display: initial;
      }
      .reasons__item {
        > div {
          .text--xlarge {
            margin-bottom: 10px;
          }
          padding: 5px;
        }
        img {
          display: none;
        }
      }

      .triangle {
        display: none;
      }
    }
  }
}

.Support {
  &__inner {
    padding: 4.5% 0 6.5%;
    @media (max-width: 400px) {
      padding: 25px 0;
    }
    .title {
      margin-bottom: 6%;
      h4 {
        margin-bottom: 25px;
      }
    }
    .agency-group {
      text-align: center;
      @include flex_parent_centered;
      width: 100%;
      max-width: 830px;
      margin: 0 auto;
      flex-wrap: wrap;
      .agency__item {
        margin: 0 10px 10px;
        min-width: 160px;
        @media (max-width: 750px) {
          min-width: 110px;
        }
        @media (max-width: 310px) {
          min-width: 75px;
        }
        img {
          display: inline-block;
          width: 115px;
          height: 75px;
          @media (max-width: 750px) {
            width: 75px;
            height: 48px;
          }
        }
      }
      .text-xlarge {
        text-transform: uppercase;
        margin: 15px 0 4px;
      }
    }
  }
}

.Contact-us {
  &__inner {
    padding: 5% 20px;
    @media (max-width: 400px) {
      padding: 25px 15px;
    }
    .title {
      margin-bottom: 36px;
      h4 {
        margin-bottom: 25px;
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}
