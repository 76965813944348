// header

.Header-onboarding {
  &__inner {
    @include flex_parent_between();
    padding: 16px;
    img {
      width: 146px;
      height: 26px;
    }
    .dropdown-toggle {
      font-weight: 600;
      width: 145px;
      min-width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding: 6px 27px 6px 12px;
      line-height: 17px;
      &::after {
        position: absolute;
        top: 7px;
        right: 8px;
      }
      @media (max-width: 420px) {
        width: 135px;
      }
    }

    .dropdown-item {
      color: $primary;
      font-weight: 600;
      svg {
        margin-right: 8px;
        path {
          fill: $primary;
        }
      }
      &:active {
        color: $primary !important;
      }
    }
  }
}

// body

.Steps {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translate(0, -50%);
  &__inner {
    .Step_item {
      display: flex;
      align-items: center;
      color: $white;
      opacity: 0.6;
      &:focus {
        outline: none;
      }
      p {
        margin: 0;
      }
      .number {
        margin-right: 12px;
        border: 2px solid transparent;
        p {
          @include size(24px);
          @include flex_parent_centered();
          border: 1.5px solid #ffffff;
          border-radius: 8px;
          margin: 2px;
        }
      }
    }
    .Step_item.isActive {
      opacity: 1;
      .number {
        border-color: $primary;
        border-radius: 10px;
        p {
          border: none;
          background: $primary;
        }
      }
    }
    .Step_item.complete {
      opacity: 1;
      .number {
        p {
          border: none;
          background: $primary;
        }
      }
    }
    img {
      margin-left: 15px;
    }
  }
  @media (max-width: 1300px) {
    left: 50px;
  }
  @media (max-width: 1000px) and (min-width: 400px) {
    left: 50%;
    top: 120px;
    transform: translate(-50%, 0);
    width: 100%;
    &__inner {
      @include flex_parent_centered();
      .Step_item {
        margin: 0 18px;
      }
      img {
        transform: rotate(90deg);
        margin: 0;
      }
    }
  }
  @media (max-width: 400px) {
    left: 50%;
    top: 120px;
    transform: translate(-50%, 0);
    width: auto;
  }
}

.Step__item {
  &__inner {
    text-align: left;
    form {
      padding: 80px 25px;
      max-width: 720px;
      @media (max-width: 1230px) {
        max-width: 530px;
      }
    }
  }
  .form__inner {
    margin: 0 auto;
    .title {
      margin-bottom: 24px;
      font-size: 21px;
    }
    .checkbox-group {
      display: flex;
      .checkbox {
        margin-right: 48px;
      }
    }
  }

  .button-group {
    display: flex;
    button {
      width: 100%;
    }
    .button--back {
      border: none;
      background: $white;
      color: $primary;
    }
  }
  @media (max-width: 1000px) {
    padding: 110px 16px 0;
    &__inner {
      min-height: calc(100vh - 200px);
    }
  }
  @media (max-width: 450px) {
    &__inner form {
      padding: 50px 20px;
      min-height: auto;
    }
    .form__inner {
      .info {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
      }
    }
    .button-group {
      button {
        padding: 0 19px;
      }
    }
  }
  @media (max-width: 400px) {
    padding: 250px 16px 0;
  }
}
.Step1 {
  .form__inner {
    max-width: 450px;
    > div:not(:last-child) {
      margin-bottom: 8px;
    }
    .Institution {
      margin-bottom: 24px !important;
      .form-label {
        font-size: 16px;
      }
    }
    .checkbox-group {
      display: flex;
      .checkbox {
        margin-right: 48px;
      }
    }

    .form-group {
      margin-bottom: 16px;
    }
    .City-state {
      display: flex;
      & > div {
        flex: 1;
      }
      > div:first-child {
        margin-right: 5px;
      }
      > div:last-child {
        margin-left: 5px;
      }
    }
    .Add {
      font-size: 14px;
      line-height: 19px;
      padding: 16px 0;
      border-top: 1px solid #d7d9dd;
      border-bottom: 1px solid #d7d9dd;
      cursor: pointer;
    }
    .branch__actions {
      display: flex;
      align-items: center;
      .checkbox {
        margin-right: 12px;
      }
      .delete {
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
      }
    }
    .Onboarding-Place {
      margin-bottom: 16px;
      &__label {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 4px;
      }
      &__error {
        color: #f7553f;
        font-size: 12px;
        line-height: 18px;
        width: 100%;
        margin-top: 0.25rem;
      }
      input {
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        font-size: 14px;
        line-height: 19px;
        height: 40px;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        &:focus,
        &:active {
          border: 1px solid #0199f2;
          box-shadow: none;
          outline: 0;
        }
        &:disabled {
          background-color: #e9ecef;
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .form__inner {
      .form-group {
        margin-bottom: 14px;
      }
      .Institution {
        .form-label {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

.branch {
  border-top: 1px solid #d7d9dd;
  padding-top: 16px;
  .btn-collapse {
    @include flex_parent_between();
    margin: 0 0 16px;
    &:focus {
      outline: none;
    }
    p {
      margin: 0;
    }
    img {
      width: 14px;
    }
    .branch-name {
      font-size: 14px;
      line-height: 19px;
      @media (max-width: 450px) {
        font-size: 12px;
        line-height: 17px;
      }
      .main-branch {
        margin-left: 4px;
      }
    }
    .icon-up {
      transform: rotate(180deg);
    }
  }
}

.Step2 {
  .form__inner {
    max-width: 564px;
    > div {
      padding-bottom: 32px;
    }
    > div:last-child {
      border-bottom: 1px solid #d7d9dd;
    }
    .state-agencies {
      .text--small {
        margin-bottom: 14px;
      }
    }
    .text--small {
      max-width: 400px;
    }
  }
  .error-message {
    color: #f7553f;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    margin-top: 0.25rem;
  }
  table {
    tr {
      th,
      td {
        padding: 16px 16px 16px 0;
        font-weight: 700;
        border-top: none;
        border-bottom: 1px solid #d7d9dd;
        &:last-child {
          padding: 16px 0;
          text-align: right;
        }
        .main-branch {
          color: $gray03;
          font-weight: normal;
          margin-left: 4px;
        }
      }
      th {
        text-transform: uppercase;
        font-size: 12px;
        color: $gray03;
      }
      td {
        font-size: 14px;
        text-transform: capitalize;
        color: $black;
        &:last-child {
          text-transform: uppercase;
          padding: 16px 0;
          text-align: right;
        }
      }
      .notSupported {
        text-transform: capitalize !important;
        color: $alert01;
      }
    }
  }
  .button-group {
    padding-top: 24px;
    max-width: 400px;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    .form__inner {
      > div {
        padding-bottom: 20px;
      }

      .text--small {
        margin-bottom: 12px;
      }
    }
    table {
      tr {
        th,
        td {
          padding: 12px 12px 12px 0;
          &:last-child {
            padding: 12px 0;
          }
        }
        th {
          font-size: 10px;
        }
        td {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}

.Step3 {
  .form__inner {
    max-width: 400px;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
    }
  }
  .text--small {
    margin: 0;
  }
}
