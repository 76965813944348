.textArea {
  @apply w-full mb-2;
}

.label {
  @apply block w-full;
  @apply font-bold text-sm leading-normal;
  @apply text-gray-900;
}

.error {
  @apply block w-full;
  @apply text-sm text-right leading-none;
  @apply text-red-400 dark:text-red-500;
}

.trigger {
  @apply w-full py-2 px-4 mb-6;
  @apply grid gap-4;
  @apply bg-white;
  @apply text-base text-gray-800 text-left;
  @apply border border-gray-300 rounded-lg;
  @apply transition-colors;

  grid-template-columns: 1fr min-content;

  &:last-child {
    @apply mb-0;
  }

  &:focus {
    @apply focus-ring;
  }

  &:hover {
    @apply border-blue-500;
  }

  &.hasError {
    @apply border-red-400;

    &:focus {
      @apply focus-ring-error;
    }
  }

  &[data-placeholder] {
    @apply text-gray-400;
  }
}

.content {
  @apply w-full;
  @apply bg-white;
  @apply text-base text-gray-800 text-left;
  @apply border border-gray-300 rounded-lg;
  @apply focus-ring drop-shadow;
}

.item {
  @apply py-2 px-4;

  &:first-child {
    @apply rounded-t-lg;
  }

  &:last-child {
    @apply rounded-b-lg;
  }

  &:hover, &:focus {
    @apply outline-none;
    @apply bg-gray-100;
    @apply cursor-pointer;
  }
}
