.form-student {
  form {
    margin: 8px 0;
  }
  &__text {
    margin: 0 12px 32px;
  }
  &__inner {
    .input__group {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin: 0 12px 16px;
        max-width: 300px;
        width: calc(100% - 24px);
      }
    }
    .checkbox-group {
      margin: 0 12px 11px;
      .checkbox {
        margin-bottom: 5px;
      }
      > p {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
      }
    }
  }
  .button-group {
    width: calc(100% - 24px);
    max-width: 300px;
    margin: 14px 12px 0;
    button:last-child {
      width: 65%;
      max-width: 190px;
    }
    @media (max-width: 360px) {
      margin: 30px 12px 0;
      button {
        width: 50% !important;
        max-width: inherit !important;
      }
    }
  }
  @media (max-width: 500px) {
    &__text {
      margin: 0 12px 25px;
    }
    &__inner {
      .input__group > div {
        margin: 0 12px 12px;
      }
      .checkbox-group {
        margin: 0 12px 12px;
        p {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 10px;
        }
        > div {
          .checkbox {
            font-size: 12px;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
