.TableWrapper {
  position: relative;
  &--loading {
    min-height: 250px;
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    margin: 0;
  }
}

.Table {
  background: #ffffff;
  padding: 12px;
  margin: 0 12px 16px;
  border-radius: 8px;
  overflow: auto;
  &__inner {
    overflow: auto;
  }
  .table-striped {
    td {
      border-bottom: none;
      border-top: none;
    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    td {
      padding: 5px 0 5px 12px;
      &:last-child {
        padding: 5px 12px;
      }
    }
  }
  .text {
    &__left {
      text-align: left;
    }
    &__center {
      text-align: center;
    }
    &__right {
      text-align: right;
    }
  }
  .table {
    margin-bottom: 0;
  }
  table {
    thead tr {
      background: #172638;
    }
    tr {
      th,
      td {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        &:first-child {
          border-radius: 8px 0 0 8px;
          min-width: 85px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
          padding-right: 12px;
          width: 40px;
        }
      }
      th {
        color: #ffffff;
        padding: 11px 0 11px 12px;
        border-bottom: 5px solid #ffffff;
        border-top: 0;
        svg {
          path {
            fill: #ffffff;
          }
        }
        .action__sort {
          margin: 0;
          padding: 4px 10px;
          position: relative;
          top: -3px;
          background-color: transparent;
          border: 0;
          &:hover {
            .action__sort__icon {
              svg {
                opacity: 1;
              }
            }
          }
          &.--is-active {
            .action__sort__icon {
              svg:nth-child(1) {
                opacity: 0;
              }
              svg:nth-child(2) {
                opacity: 1;
              }
              &.--desc {
                svg:nth-child(1) {
                  opacity: 1;
                }
                svg:nth-child(2) {
                  opacity: 0;
                }
              }
            }
          }
          &__icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            svg {
              opacity: 0.3;
            }
          }
        }
      }
      td {
        border-top: none;
        padding: 7px 0 7px 12px;
        &:last-child {
          text-align: right;
          cursor: pointer;
          svg {
            vertical-align: -0.125rem;
            path {
              fill: white;
            }
          }
        }
      }
      @media (max-width: 600px) {
        th,
        td {
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
  }
}
