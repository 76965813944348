.dashboard__wrap {
  position: relative;
  z-index: 2;
  height: calc(100vh - 48px);
  overflow: hidden auto;
  background: #f6f6f9;
}

.dashboard {
  padding: 40px 16px 0;
  max-width: 1028px;
  margin: 0 auto;
  @media (max-width: 400px) {
    padding: 40px 4px 0;
  }
  h4 {
    font-family: "Open Sans", sans-serif;
  }
  .text--title {
    font-size: 14px;
    line-height: 19px;
    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.Filter {
  &__inner {
    > div {
      display: flex;
    }
    .title {
      margin: 0 12px 20px;
      align-items: center;
      h4 {
        margin: 0;
      }
      img {
        margin-right: 16px;
        height: 48px;
        border-radius: 100%;
      }
    }
    .list-filter {
      margin-bottom: 24px;
      > div {
        width: calc(33.33% - 24px);
        margin: 0 12px;
      }
    }
    .stats {
      &__item {
        width: 50%;
        display: flex;
        > div {
          width: calc(50% - 24px);
          margin: 0 12px 24px;
          box-shadow: 0px 0px 25px 10px rgba(26, 26, 26, 0.06);
          border-radius: 8px;
          padding: 16px 24px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          h4,
          p {
            margin: 0;
          }
          h4 {
            line-height: 43px;
          }
          .--noData {
            margin: 11px 0 16px;
          }
          @media (max-width: 500px) {
            min-height: 78px;
            h4 {
              line-height: 30px;
            }
            .--noData {
              margin: 7px 0 0px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .stats {
      display: block;
      &__item {
        width: 100%;
      }
    }
  }
  @media (max-width: 760px) {
    &__inner {
      .list-filter {
        margin-bottom: 16px;
      }
      .stats__item > div {
        margin-bottom: 16px;
      }
    }
  }
  @media (max-width: 660px) {
    &__inner {
      .list-filter {
        display: block;
        > div {
          width: calc(100% - 24px);
          margin: 0 12px 14px;
          .dropdown-toggle {
            height: 40px;
          }
        }
      }
      .stats__item > div {
        padding: 16px 14px;
      }
    }
  }
  @media (max-width: 525px) {
    &__inner .title h4 {
      font-size: 25px;
    }
  }
  @media (max-width: 400px) {
    &__inner {
      .title h4 {
        font-size: 21px;
        line-height: 28px;
      }
      .stats__item > div {
        h4 {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}

.Chart {
  &__inner {
    display: flex;
    > div {
      width: calc(50% - 24px);
      margin: 0 12px 24px;
      padding: 24px 24px 40px;
      .title {
        margin-bottom: 10px;
      }
      .chart__item {
        display: flex;
        align-items: center;
        .infoChart:not(:last-child) {
          margin-bottom: 24px;
        }
        .infoChart {
          display: flex;
          align-items: center;
          span {
            @include size(20px);
            border-radius: 8px;
            display: block;
            margin-right: 8px;
          }
          p {
            margin: 0;
          }
          span.newly {
            background: #244c7c;
          }
          span.transferred {
            background: #0199f2;
          }
          span.re-enrolled {
            background: #ffbc42;
          }
        }
        .recharts-layer text {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .enrollment-chart {
        .recharts-wrapper {
          margin-right: 60px;
        }
      }
      .complete-chart {
        &__left {
          margin-right: 60px;
          position: relative;
          .percent-complete {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            h4 {
              margin-bottom: 2px;
            }
            p {
              margin: 0;
            }
          }
        }
      }
      .Nodata__chart {
        p {
          margin-bottom: 15px;
          text-align: left;
        }
        img {
          width: 100%;
          max-width: 335px;
        }
      }
    }
    @media (max-width: 1160px) {
      display: block;
      > div {
        width: calc(100% - 24px);
        .chart__item {
          justify-content: center;
        }
        .Nodata__chart {
          text-align: center;
        }
      }
    }
    @media (max-width: 716px) {
      > div {
        .chart__item {
          flex-direction: column;
          .infoChart:not(:last-child) {
            margin-bottom: 16px;
          }
        }
        .enrollment-chart .recharts-wrapper {
          margin: 0 0 20px 0;
        }
        .complete-chart__left {
          margin: 0 0 20px 0;
        }
      }
    }
    @media (max-width: 660px) {
      > div {
        margin: 0 12px 16px;
        padding: 20px 16px 30px;
      }
    }
  }
}

.Placement {
  &__inner {
    display: flex;
    .Nodata {
      img {
        width: 100%;
      }
    }
    &__left {
      width: calc(50% - 24px);
      margin: 0 12px 24px;
      padding: 24px;
      .title {
        margin-bottom: 26px;
      }
      .placement--item:not(:last-child) {
        margin-bottom: 14px;
      }
      .Nodata {
        p {
          margin: -14px 0 20px;
        }
        img {
          max-width: 400px;
        }
      }
      .placement--item {
        > div {
          display: flex;
          align-items: center;
          .range {
            display: block;
            height: 14px;
            background: $primary;
            border-radius: 16px;
            margin-right: 4px;
            p {
              margin: 0;
            }
          }
        }
        p {
          margin-bottom: 2px;
        }
      }
    }
    &__right {
      width: 50%;
      p {
        margin: 0;
      }
      .title {
        margin-bottom: 14px;
      }
      .Nodata {
        p {
          margin: 0 0 5px;
        }
        img {
          max-width: 180px;
        }
        .img_group {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          img {
            width: calc(50% - 30px);
            &:first-child {
              margin-right: 20px;
            }
          }
        }
        @media (max-width: 730px) {
          .img_group {
            img {
              min-width: 180px;
              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }
      }
      .info {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        svg {
          margin-right: 8px;
        }
      }
      > div:first-child {
        display: flex;
        margin-bottom: 24px;
        > div {
          width: calc(50% - 24px);
          margin: 0 12px;
        }
      }
      .weekly--item {
        padding: 24px 16px 8px;
      }
      .salary {
        margin: 0 12px 24px;
        &__inner {
          display: flex;
          flex-wrap: wrap;
          .percent {
            width: 50%;
          }
        }
      }
      .percent {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        span {
          color: #244c7c;
          background: #def2ff;
          border-radius: 8px;
          padding: 6px 8px;
          font-size: 18px;
          line-height: 20px;
          font-weight: 600;
          margin-right: 12px;
          width: 70px;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    &__inner {
      display: block;
      &__left {
        width: calc(100% - 24px);
      }
      &__right {
        width: 100%;
      }
    }
  }
  @media (max-width: 660px) {
    .Placement__inner__left {
      margin-bottom: 16px;
      padding: 20px 16px;
    }
    .Placement__inner__right {
      > div:first-child {
        display: block;
        margin-bottom: 0;
        > div {
          width: calc(100% - 24px);
          margin-bottom: 16px;
          padding: 20px 16px 8px;
        }
      }
      .salary {
        margin-bottom: 16px;
        padding: 20px 16px 8px;
      }
      .salary__inner {
        display: block;
        .percent {
          width: 100%;
        }
      }
      .percent span {
        padding: 4px 12px;
        font-size: 15px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: 400px) {
    .Placement__inner__left {
      .placement--item:not(:last-child) {
        margin-bottom: 12px;
      }
      .placement--item > div .range {
        height: 10px;
      }
    }
  }
}

.Survey {
  &__inner {
    display: flex;
    > div {
      width: calc(50% - 24px);
      margin: 0 12px 24px;
    }
    .Nodata {
      p {
        margin: 0 0 20px 10px;
      }
      img {
        width: 100%;
        margin-bottom: 8px;
      }
    }
    &__left {
      > div {
        padding: 24px 14px;
      }
      > div:first-child {
        margin-bottom: 24px;
      }
      .title {
        margin: 0 0 8px 10px;
      }
      .table-wrap {
        overflow-x: auto;
        min-height: 200px;
      }
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: #f6f6f9;
      }
      table {
        min-width: 400px;
        tbody tr {
          &:hover {
            background: #e5e7eb !important;
          }
        }
        tr {
          th,
          td {
            font-size: 12px;
            line-height: 16px;
            border: none;
            font-weight: 600;
            text-transform: capitalize;
            &:last-child {
              display: flex;
              justify-content: space-between;
              align-items: center;
              max-width: 88px;
              margin: 0 0 0 auto;
            }
          }
          th {
            color: $gray03;
            padding: 8px 0 16px 10px;
          }
          td {
            color: $black;
            padding: 8px 0 8px 10px;
            &:last-child {
              svg {
                margin: 0 10px;
              }
            }
          }
        }
      }
      .table__recent-outcome {
        table tr td:nth-child(2) {
          min-width: 70px;
        }
      }
    }
    &__right {
      padding: 24px;
      .Nodata {
        p {
          margin: 0 0 20px 0;
        }
        img {
          max-width: 370px;
        }
      }
      .title {
        margin-bottom: 7px;
      }
      .today {
        color: $gray03;
        margin-bottom: 20px;
      }
      .activity:not(:last-child) {
        margin-bottom: 16px;
      }
      .activity {
        display: flex;
        &__left {
          display: flex;
          flex-direction: column;
          margin-right: 18px;
          align-items: center;
          .icon {
            @include flex_parent_centered();
            border-radius: 100%;
            width: 32px;
            height: 32px;
            margin-bottom: 10px;
          }
          .edit {
            background: #d89ea8;
          }
          .plus {
            background: #76cb93;
          }
          .delete {
            background: #ffbc42;
          }
        }
        &__right {
          width: calc(100% - 32px);
          > p:first-child {
            margin-bottom: 8px;
          }
          > div {
            @include flex_parent_between();
            p {
              margin: 0;
              color: $gray03;
            }
          }
          .activity__text {
            span {
              color: $primary;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    &__inner {
      display: block;
      > div {
        width: calc(100% - 24px);
      }
    }
  }
  @media (max-width: 660px) {
    &__inner {
      > div {
        margin-bottom: 0;
      }
      &__left {
        > div {
          margin-bottom: 16px !important;
          padding: 20px 14px;
        }
        table {
          min-width: 360px;
          tr {
            td,
            th {
              font-size: 11px;
              padding: 7px 0 7px 10px;
            }
          }
        }
      }
      &__right {
        margin-bottom: 16px !important;
        padding: 20px 14px;
      }
    }
  }
  @media (max-width: 400px) {
    .Survey__inner__right .activity__right > div {
      display: block;
    }
  }
}
