.add-document {
  .dropzone {
    background: #def2ff;
    border: 1px dashed #0199f2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 35px 20px;
    text-align: center;
    button {
      border-radius: 8px;
      padding: 0 24px;
    }
    p {
      margin: 16px 0 0 0;
    }
  }
}

.Documents {
  margin: 0 12px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .Document--item {
    @include flex_parent_between();
    background: #e5e7eb;
    border-radius: 8px;
    width: 240px;
    height: 40px;
    padding: 10px 16px;
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 24px;
    }
    p {
      font-weight: 400;
      width: calc(100% - 20px);
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button {
      border: none;
      background: transparent;
      padding: 0 4px;
      color: $black;
      &:hover {
        color: $alert01;
      }
      svg path {
        fill: currentColor;
      }
    }
  }
}
