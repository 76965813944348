.Survey-steps {
  .logo {
    padding: 16px;
    img {
      width: 146px;
      height: 26px;
    }
  }
  .Step {
    &__inner {
      @include flex_parent_centered();
      text-align: center;
      min-height: calc(100vh - 60px);
      padding: 50px 0;
      .form_wrap {
        width: 100%;
        img {
          height: 60px;
        }
      }
      form {
        background: #ffffff;
        border-radius: 8px;
        max-width: 720px;
        width: calc(100% - 40px);
        margin: 20px auto;
        padding: 56px 20px;
        @media (max-width: 500px) {
          padding: 30px 20px;
        }
      }
      .form__inner {
        max-width: 555px;
        margin: 0 auto;
      }
      label {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 4px;
      }
      &__header {
        border-bottom: 1px solid #e5e7eb;
        max-width: 400px;
        margin: 0 auto;
        p {
          font-size: 14px;
          line-height: 19px;
          color: #68717b;
          margin-top: 12px;
          margin-bottom: 24px;
          font-weight: 600;
        }
      }
      &__body {
        .title {
          font-size: 24px;
          line-height: 33px;
          font-weight: 600;
          @media (max-width: 500px) {
            font-size: 18px;
            line-height: 25px;
          }
        }
        .form-group,
        .Date-picker,
        .select,
        .Place {
          max-width: 400px;
          margin: 0 auto 16px;
          text-align: left;
        }
        .checkbox-group {
          p {
            font-size: 14px;
            line-height: 19px;
          }
          > div {
            max-width: 360px;
            margin: 0 auto;
          }
          .checkbox {
            margin: 0 24px;
          }
          &.yes-no {
            > div {
              display: flex;
              justify-content: center;
            }
          }
        }

        padding: 24px 0 8px;
      }
    }
    .button-group {
      max-width: 400px;
      margin: 0 auto;
      padding-top: 24px;
      border-top: 1px solid #e5e7eb;
      button {
        width: 50%;
      }
    }
    &.step1 {
      .Step__inner .form__inner {
        max-width: 600px;
      }
      h4 {
        margin: 8px 0 24px;
      }
      p {
        margin-bottom: 8px;
      }
      button {
        margin-top: 16px;
        width: 100%;
        max-width: 200px;
      }
      @media (max-width: 500px) {
        h4 {
          margin: 5px 0 16px;
        }
      }
    }
    &.step3 {
      .checkbox-group {
        @include flex_parent_centered();
      }
      .checkbox {
        margin: 0 24px;
      }
    }
    &.step5 {
      .checkbox-group {
        max-width: 314px;
        margin: 0 auto;
      }
      .form-group {
        margin: 0 auto 5px;
      }
      .Code__item {
        margin-bottom: 16px;
        .description__text {
          text-align: left;
        }
      }
    }
    &.step7,
    &.step6 {
      .Step__inner__body {
        max-width: 400px;
        margin: 0 auto;
        .checkbox-group {
          text-align: left;
          > div {
            max-width: initial;
            .checkbox {
              margin: 0;
            }
          }
        }
      }
    }
    &.step8 {
      .Step__inner .form__inner {
        max-width: 608px;
      }
      .add-document {
        margin-bottom: 16px;
      }
      .Documents {
        margin: 0;
        &__inner {
          padding: 8px 0;
        }
        .Document--item {
          width: 100%;
          max-width: 400px;
          margin: 0 auto 24px;
          &:last-child {
            margin: 0 auto 8px;
          }
        }
      }
    }
    &.step9 {
      .follow-document {
        width: 100%;
        max-width: 205px;
        margin: 0 auto;
        text-align: left;
      }
    }
    &.step10 {
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.Range {
  @include flex_parent_between();
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  > div {
    background: #68717b;
    border-radius: 8px;
    width: calc(100% - 28px);
    height: 3px;
  }
  span {
    background: #0199f2;
    border-radius: 8px;
    width: 100px;
    height: 3px;
    display: block;
  }
  &.--iconActive {
    svg path {
      fill: $primary;
    }
  }
}

.Modal__delete__document {
  margin: 1.75rem auto;
}
