.header {
  @apply text-center;
}

.schoolName {
  @apply mb-2;
  @apply text-3xl font-bold;
}

.surveyTitle {
  @apply text-base font-sans font-bold;
}

.surveyInformation {
  @apply text-justify;
}

.changeRequest {
  @apply w-full p-2;
  @apply bg-yellow-100 text-yellow-800;
  @apply border border-dashed border-yellow-300 rounded-lg;
  @apply text-justify;

  h2 {
    @apply pb-2;
    @apply text-sm font-bold;
  }
}
