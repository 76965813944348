.Tasks {
  .filter {
    display: flex;
    justify-content: space-between;
    margin: 0 12px 24px;
    &__left {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 120px);
      > div {
        width: 100%;
        max-width: 308px;
        margin: 0 24px 12px 0;
        min-width: 188px;
      }
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  &__link {
    display: block;
    color: var(--bs-table-striped-color);
    &:hover {
      color: #0199f2;
    }
  }
  table {
    min-width: 900px;
    tr {
      th,
      td {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 12%;
        }
        &:nth-child(4) {
          width: 14%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 20%;
        }
        &:nth-child(7) {
          width: 20%;
        }
        &:last-child {
          text-align: left;
        }
      }
    }
    @media (max-width: 600px) {
      min-width: 720px;
    }
  }

  .tasks__status {
    text-transform: capitalize;
    &--pending {
      color: $warning01;
    }
    &--completed {
      color: $success01;
    }
    &--in_review {
      color: $primary01;
    }
    &--rejected {
      color: $alert01;
    }
  }

  .tasks__student__name {
    display: block;
    cursor: pointer;
    margin: 0;
    color: var(--bs-table-striped-color);
    &:hover {
      color: $primary;
    }
  }
}
