.Students {
  .Title {
    max-width: 1200px;
  }
  &__inner {
    max-width: 1200px;
    .filter {
      > div {
        display: flex;
        align-items: center;
      }
      &-1 {
        justify-content: space-between;
        .form-group,
        button {
          margin: 0 12px 18px;
        }
        .form-group {
          min-width: 200px;
        }
        &__right {
          display: flex;
          align-items: center;
        }
      }
      &-2 {
        margin-bottom: 10px;
        flex-wrap: wrap;
        > div {
          width: calc(16.66% - 24px);
          min-width: 135px;
          margin: 0 12px 10px;
        }
      }
      @media (max-width: 870px) {
        &-1 {
          display: block !important;
        }
      }
      @media (max-width: 600px) {
        &-2 {
          > div {
            width: calc(50% - 24px);
          }
        }
      }
      @media (max-width: 430px) {
        &-1__right {
          display: block !important;
        }
      }
      @media (max-width: 350px) {
        &-2 {
          margin: 0 5px 16px;
          > div {
            min-width: 124px;
            margin: 0 7px;
            width: calc(50% - 14px);
          }
          .SingleSelectCheckbox .dropdown-toggle {
            padding: 9px 22px 9px 10px;
            &::after {
              right: 5px;
            }
          }
        }
      }
    }
    table {
      min-width: 900px;
      tr {
        th,
        td {
          vertical-align: middle;
          &:nth-child(2) {
            width: 10%;
          }
          &:first-child {
            > div:focus {
              outline: none;
              color: $primary;
            }
            &:hover {
              color: $primary;
              transition: 0.3s ease;
            }
          }
        }
      }
      @media (max-width: 600px) {
        min-width: 720px;
      }

      .name-with-notifications {
        position: relative;
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: center;
      }

      .notifications {
        font-size: 20px;
        color: #ffbc42;
      }
    }
  }
  .AddStudent {
    .checkbox-group {
      > div {
        display: flex;
        .checkbox:not(:last-child) {
          margin-right: 35px;
        }
      }
    }
  }
}
