.button {
  @apply w-full py-4 px-8 rounded-lg;
  @apply font-bold text-sm;
  @apply bg-white text-blue-500;
  @apply transition;
  @apply cursor-pointer select-none;

  &:focus {
    @apply focus-ring;
  }

  &:hover {
    @apply bg-gray-100;
  }

  &:active {
    @apply bg-gray-100 shadow-inner;
  }
}

.primary {
  @apply bg-blue-500 text-white;

  &:hover {
    @apply bg-blue-400;
  }

  &:active {
    @apply bg-blue-600 shadow-inner;
  }
}
