.Institution {
  .Header-institution {
    background: #ffffff;
    padding: 40px 28px 0;
    max-width: 1030px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 400px) {
      padding: 40px 16px 0;
    }
    &__title {
      @include flex_parent_between();
      margin-bottom: 12px;
      > div {
        display: flex;
        align-items: center;
      }
      img {
        height: 48px;
        border-radius: 100%;
      }
      h4 {
        margin: 0 0 0 16px;
      }
    }
    &__taps {
      display: flex;
      justify-content: space-between;
      max-width: 500px;
      width: 100%;
      a {
        color: #68717b;
        padding: 16px 0;
        transition: 0.3s ease;
        border-bottom: 2px solid transparent;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &.isActive {
          color: #16222f;
          border-color: #ffbc42;
        }
        &:hover {
          color: #16222f;
          border-color: #ffbc42;
          text-decoration: none;
        }
      }
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5000px;
      background: white;
    }
    &::after {
      left: 100%;
    }
    &::before {
      right: 100%;
    }
  }

  .pull-step2 {
    .Table,
    .Table__inner {
      overflow: initial !important;
    }
  }
}

.General {
  &__inner {
    display: flex;
    justify-content: space-between;
    .info,
    button {
      margin: 8px 12px;
    }
    .info {
      width: calc(100% - 186px);
      max-width: 550px;
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        p {
          margin: 0;
          &:first-child {
            width: 70%;
            max-width: 360px;
          }
          &:last-child {
            width: 19%;
          }
        }
        img {
          position: relative;
          bottom: 12px;
          height: 50px;
          border-radius: 100%;
        }
      }
    }
    button {
      width: 138px;
    }
    @media (max-width: 765px) {
      flex-direction: column;
      .info {
        width: calc(100% - 24px);
      }
    }
    @media (max-width: 600px) {
      .info {
        width: calc(100% - 24px);
        > div {
          margin-bottom: 20px;
          p:last-child {
            width: 26%;
            max-width: 80px;
          }
        }
        img {
          width: 32px;
        }
      }
    }
  }
}

.Edit-institution {
  form {
    margin: 8px 12px;
    max-width: 510px;
  }
  &__text {
    margin-bottom: 32px;
  }
  &__inner {
    position: relative;
  }
  .form-group {
    margin-bottom: 16px;
    max-width: 310px;
  }
  .checkbox-group {
    margin-bottom: 16px;
    .form-group {
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .survey__internal {
    p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 8px;
    }
    .SelectCheckbox {
      width: 83px;
      .dropdown-toggle {
        padding: 7px 30px 7px 16px;
      }
    }
    .frequency {
      margin-bottom: 16px;
      .SelectCheckbox {
        margin: 0 16px;
      }
      > div {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
      }
    }
    .time-interval {
      .checkbox-group {
        margin-bottom: 12px;
      }
    }
    .select-month {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      .SelectCheckbox {
        margin-right: 16px;
      }
    }
  }
  .logo {
    margin-bottom: 12px;
    img {
      @include size(50px);
      border-radius: 100%;
    }
    .button__delete {
      margin-left: 10px;
      background: transparent;
      border: none;
      svg path {
        fill: $black;
      }
    }
  }

  .input__group {
    margin-bottom: 16px;
    .add-document {
      cursor: pointer;
      @include size(310px);
      max-width: 310px;
      position: absolute;
      top: 33px;
      overflow: hidden;
      opacity: 0;
    }
  }

  .button-group {
    width: 100%;
    max-width: 310px;
    margin-top: 50px;
    button:last-child {
      width: 65%;
      max-width: 200px;
    }
    @media (max-width: 360px) {
      margin-top: 35px;
      button {
        width: 50% !important;
        max-width: inherit !important;
      }
    }
  }
  @media (max-width: 500px) {
    .checkbox-group {
      .form-group {
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.Administrator {
  .add-administrator {
    text-align: right;
    margin: 0 12px 26px;
    .button--icon {
      margin: 0 0 0 auto;
    }
  }
  table {
    min-width: 540px;
    tr {
      th,
      td {
        &:first-child {
          min-width: 160px;
          width: 37%;
        }
      }
    }
    @media (max-width: 600px) {
      min-width: 450px;
    }
  }
}

.AddAdministrator {
  form {
    margin: 8px 0;
  }
  &__text {
    margin: 0 12px 32px;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin: 0 12px 24px;
      max-width: 300px;
      width: calc(100% - 24px);
    }
  }
  .button-group {
    width: calc(100% - 24px);
    max-width: 300px;
    margin: 50px 12px 0;
    button:last-child {
      width: 65%;
      max-width: 190px;
    }
    @media (max-width: 360px) {
      margin: 30px 12px 0;
      button {
        width: 50% !important;
        max-width: inherit !important;
      }
    }
  }
  @media (max-width: 500px) {
    &__text {
      margin: 0 12px 25px;
    }
    &__inner {
      > div {
        margin: 0 12px 12px;
      }
    }
  }
}

.RoleTable {
  &__inner {
    text-align: right;
    margin: 0 12px 26px;
    .button--icon {
      margin: 0 0 0 auto;
    }
  }
  table {
    min-width: 450px;
    @media (max-width: 600px) {
      min-width: 370px;
    }
  }
}

.AddRole {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
  form {
    margin: 8px 12px;
  }
  &__text {
    margin: 0 0 32px;
  }
  &__inner {
    > .form-group {
      max-width: 310px;
      margin-bottom: 16px;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      margin: 0;
    }
    .checkbox-group {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      &.institution {
        margin-bottom: 32px;
      }
      &__inner {
        @include flex_parent_between();
        max-width: 350px;
        p {
          margin-right: 16px;
        }
        > div {
          display: flex;
        }
        .checkbox:first-child {
          margin-right: 24px;
        }
      }
      &.accessLevelAll {
        .checkbox-group__inner:first-child {
          margin-bottom: 16px;
        }
      }
    }
    .access-level {
      margin-bottom: 16px;
      p {
        margin-bottom: 16px;
      }
      > div {
        display: flex;
        .checkbox:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
  .button-group {
    width: calc(100% - 24px);
    max-width: 310px;
    margin: 32px 12px 0;
    button:last-child {
      width: 65%;
      max-width: 200px;
    }
    @media (max-width: 360px) {
      margin: 24px 12px 0;
      button {
        width: 50% !important;
        max-width: inherit !important;
      }
    }
  }
  @media (max-width: 500px) {
    &__text {
      margin: 0 0 25px;
    }
    &__inner {
      > .form-group {
        margin-bottom: 12px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
      }
      .checkbox {
        font-size: 12px;
      }
      .checkbox-group {
        padding: 10px 16px;
      }
    }
  }
  @media (max-width: 400px) {
    &__inner {
      .access-level {
        p {
          margin-bottom: 12px;
        }
        > div {
          display: block;
        }
      }
    }
  }
}

.Access-level_table {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  &__inner {
    overflow: auto;
    max-height: 175px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  table {
    margin-bottom: 0;
    min-width: 410px;
    tr {
      th {
        position: sticky;
        top: 0;
        background: #ffffff;
        z-index: 99;
      }
      td,
      th {
        border: none;
        font-size: 14px;
        padding: 6px 10px 6px 0;
        font-weight: 400;
        &:first-child {
          font-weight: 600;
          width: 45%;
        }
        &:last-child {
          padding-right: 0;
          max-width: 230px;
          width: 27%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    p {
      font-size: 14px;
      line-height: 19px;
    }
    table {
      min-width: 360px;
      tr {
        td,
        th {
          border: none;
          font-size: 14px;
          padding: 7px 10px 7px 0;
          &:first-child {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    .checkbox {
      line-height: 16px;
      .form-group .checkmark {
        top: 0px;
      }
    }
  }
}

.Account {
  &__row {
    max-width: 600px;
    width: 100%;
    display: flex;
    p:first-child {
      padding-right: 16px;
      max-width: 300px;
      width: 100%;
    }
    input {
      width: 300px;
    }
  }
}
