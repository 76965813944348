.Surveys {
  .filter {
    display: flex;
    margin: 0 12px 8px;
    flex-wrap: wrap;
    > div,
    input {
      width: calc(50% - 24px);
      max-width: 303px;
      margin: 0 24px 12px 0;
      min-width: 188px;
    }
    .button--icon {
      margin-top: 2px;
    }
    @media (max-width: 365px) {
      margin-bottom: 16px;
      display: block;

      &__left {
        width: 100%;
        > div {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      button {
        margin: 0 0 0 auto;
      }
    }
  }
  table {
    min-width: 400px;
    tr td:last-child {
      svg path {
        fill: $primary;
      }
    }

    .show-survey-results {
      border: 0;
      cursor: pointer;
      background: none;
      padding: 0;
      width: 100%;
      text-align: right;
    }
  }
}
