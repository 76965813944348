.header {
  @include position(fixed, 0 0 null 0);
  z-index: 1;

  .navbar {
    background: $white;
    padding: 17px 40px;
    min-height: $minHeightHeader;
    box-shadow: 25px 10px 25px rgba(26, 26, 26, 0.06);
    img {
      height: 36px;
    }
    @media (max-width: 500px) {
      padding: 17px 20px;
      img {
        height: 32px;
      }
    }
    @media (max-width: 315px) {
      img {
        height: 26px;
      }
    }
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 20px;
    color: $black;
    transition: 0.25s ease;
    white-space: nowrap;
    &:hover {
      color: $primary;
    }
    @media (max-width: 1120px) and (min-width: 1020px) {
      font-size: 15px;
    }
  }
  .-isDarkHeader {
    background: $black;
    box-shadow: none;
    .navbar-nav .nav-link {
      color: $white;
    }
    .navbar-toggler {
      background: $white;
    }
  }
  .Scrollspy-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      list-style: none;
      &.--is-current {
        .nav-link {
          color: $primary !important;
        }
      }
    }
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: start;
    }
  }

  button {
    margin-left: 20px;
    height: 44px;
  }

  .button-register {
    text-decoration: unset;
    @media (max-width: 991px) {
      margin-top: 12px;
    }
  }

  @media (max-width: 1120px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding: 8px 10px;
    }
    button {
      margin-left: 10px;
    }
  }
}
