/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "danger": #f7553f,
  "primary": #0199f2,
);

/* import bootstrap to set changes */
.bootstrap {
  @import "~bootstrap/scss/bootstrap";
}

// -----------------------------
// Global
// -----------------------------
@import "./global/functions";
@import "./global/variables";
@import "./global/mixins";
@import "./global/utilities";
// // -----------------------------

@import "./common/modal";

.bootstrap {
  @import "./global/typography";
  @import "./global/general";

  // Common
  @import "./common/button";
  @import "./common/input";
  @import "./common/message";
  @import "./common/form";
  @import "./common/dropdown";
  @import "./common/selectCheckbox";
  @import "./common/breadcrumb";
  @import "./common/dateRangePicker";
  @import "./common/singleDatePicker";
  @import "./common/pagination";
  @import "./common/formStudent";
  @import "./common/dropzone";
  @import "./common/loading";
  @import "./common/typeahead";

  // Layout
  @import "./layout/header";
  @import "./layout/footer";

  // All components
  @import "./components/table";

  // All pages
  @import "./pages/landing";
  @import "./pages/contactUs";
  @import "./pages/textPage";
  @import "./pages/signUp";
  @import "./pages/login";
  @import "./pages/forgotPassword";
  @import "./pages/resetPassword";
  @import "./pages/onBoardingPage";
  @import "./pages/sidebarAndHeader";
  @import "./pages/dashboard";
  @import "./pages/reporting";
  @import "./pages/programs";
  @import "./pages/financialAidPrograms.scss";
  @import "./pages/branches";
  @import "./pages/cohorts";
  @import "./pages/campaigns";
  @import "./pages/responses";
  @import "./pages/tasks";
  @import "./pages/institution";
  @import "./pages/students";
  @import "./pages/student";
  @import "./pages/surveys";
  @import "./pages/surveyResults";
  @import "./pages/surveySteps";
  @import "./pages/importStudent";
  @import "./pages/pricing";
  @import "./pages/profileSetting";
  @import "./pages/integrations";
  @import "./pages/modalSendSurvey";
}
