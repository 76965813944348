.ResetPassword {
  .form__inner {
    padding: 24px 25px;
    .text--xsmall {
      margin: 24px 0;
    }
    button {
      width: 100%;
      margin-top: 8px;
    }
  }

  @media (max-width: 500px) {
    .form__inner {
      padding: 16px 15px 14px;
    }
  }
}
