.summary {
  @apply text-sm font-bold text-blue-500;

  &[data-state="open"] {
    .icon {
      @apply rotate-180;
    }
  }

  &[data-state="closed"] {
    .icon {
      @apply rotate-0;
    }
  }
}

.icon {
  @apply text-xs ml-2;
  margin-bottom: 1px;
}

.details {
  @apply mt-1;
  @apply text-sm text-justify text-gray-800;
  @apply overflow-hidden;

  &[data-state="open"] {
    animation: details-open 250ms ease-out;
  }

  &[data-state="closed"] {
    animation: details-close 250ms ease-out;
  }
}

@keyframes details-open {
  from {
    height: 0px;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes details-close {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0px;
  }
}
