.formGroup {
  @apply mb-2;

  h2 {
    @apply mb-2;
    @apply font-bold text-xl text-left;
  }
}

.inputGroup2,
.inputGroup3 {
  @apply grid gap-4 grid-cols-2;
  @apply mb-8;

  fieldset > button {
    @apply mb-0;
  }

  @media screen and (max-width: 640px) {
    @apply grid-cols-1;
  }
}

.inputGroup3 {
  @apply grid gap-4 grid-cols-3;

  @media screen and (max-width: 640px) {
    @apply grid-cols-1;
  }
}
