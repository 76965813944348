.Breadcrumb {
  display: flex;
  align-items: center;
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $gray02 !important;
    text-decoration: none !important;
    p {
      font-size: 14px;
      line-height: 19px;
      margin: 0;
    }
    svg {
      margin: 0 5px;
    }
    &:hover p {
      text-shadow: 0.25px 0.25px #000;
    }
    &.--is-active {
      p {
        text-shadow: 0.25px 0.25px #000;
      }
    }
  }
}
