.area {
  @apply w-full p-8;
  @apply bg-blue-100;
  @apply border border-dashed border-blue-500 rounded;
  @apply text-center;
  @apply transition select-none;

  &:focus {
    @apply focus-ring;
  }

  &.isDragActive {
    @apply bg-blue-50 focus-ring;
  }
}

.text {
  @apply text-center text-blue-500;
}

.button {
  @apply inline-block py-2 px-4 mb-8 rounded-lg;
  @apply font-bold text-sm;
  @apply bg-blue-500 text-white;
  @apply transition;
  @apply cursor-pointer select-none;

  &:hover {
    @apply bg-blue-400;
  }
}

.previouslyUploadedFiles {
  @apply w-full p-4;
  @apply bg-gray-100 rounded-lg;
  @apply grid gap-2 grid-cols-1;

  h2 {
    @apply text-sm pb-2 text-gray-800;
  }
}

.files {
  @apply w-full;
  @apply grid gap-2 grid-cols-1;
}
